import cssStyles from "./QuickInsightsReport.module.css"
import {useEffect, useState} from "react";
import {addAuthorizationHeader} from "../../../utils/Api";
import ReportMenuBar from "./ReportMenuBar";
import {REPORTS} from "./ReportConstants";
import ReportPercentFragment from "./ReportFragments/ReportPercentFragment";
import {NodeType} from "../../../model/Constants";
import ErrorBox from "../../ErrorBox/ErrorBox";
import {Link} from "../QuickTutorial/Link";

export default function QuickInsightsReport({workspaceId, user}) {

    const [capabilityReport, setCapabilityReport] = useState({})
    const [dataflowReport, setDataflowReport] = useState({})
    const [dataexchangeReport, setDataexchangeReport] = useState({})

    const [errorLoading, setErrorLoading] = useState(false)

    useEffect(()=>{
        fetch("/api/report-quick-insights", user && {
            // only attach headers if user is logged in, but still make the request regardless
            headers: addAuthorizationHeader({}, workspaceId, user)
        })
            .then(res =>{
                if (res.status === 200) {
                    return res.json()
                } else {
                    throw new Error("Error fetching report count data")
                }
            })
            .then(data=>{
                console.debug("report quality data:", data)
                if (data?.capabilityReport) {
                    const capabilityReport = data?.capabilityReport
                    setCapabilityReport({
                        ...capabilityReport,
                        withoutApplicationsPercentage: (capabilityReport.totalCount>0?Math.round(((capabilityReport.withoutApplicationsCount/capabilityReport.totalCount)*100))/100:0)
                    })
                }

                if (data?.dataflowReport) {
                    const dataflowReport = data?.dataflowReport
                    setDataflowReport({
                        ...dataflowReport,
                        withoutDataExchangesPercentage: (dataflowReport.totalCount>0?Math.round(((dataflowReport.withoutDataExchangesCount/dataflowReport.totalCount)*100))/100:0)
                    })
                }

                if (data?.dataexchangeReport) {
                    const dataexchangeReport = data?.dataexchangeReport
                    setDataexchangeReport({
                        ...dataexchangeReport,
                        withoutSupportingMiddlewarePercentage: (dataexchangeReport.totalCount>0?Math.round(((dataexchangeReport.withoutSupportingMiddlewareCount/dataexchangeReport.totalCount)*100))/100:0)
                    })
                }

            })
            .catch((e)=>{
                console.error("Error fetching capability analysis: ", e)
                setErrorLoading(true)
            })
    }, [workspaceId, user])

    return <div className={cssStyles.main}>
        <ReportMenuBar
            user={user}
            report={REPORTS.quickInsightsReport}
        />
        {
            errorLoading && <ErrorBox isCloseable={false} id={"countreport-errorbox"}>
                Error loading report data. If the error persists, please <Link type={"error"} href={"https://docs.kenchiku.io/support/tickets/new"}>contact support</Link> .
            </ErrorBox>
        }
        <div className={cssStyles.report}>
            {!errorLoading && capabilityReport && <ReportPercentFragment
                testId={"capabilities-without-Applications"}
                label={"% Capabilities Without Applications"}
                count={capabilityReport.withoutApplicationsCount}
                total={capabilityReport.totalCount}
                percentage={capabilityReport.withoutApplicationsPercentage}
                nodeTypeName={NodeType.Capability.description}
            />}
            {!errorLoading && <ReportPercentFragment
                testId={"dataflows-without-dataexchanges"}
                label={"% Dataflows Without Dataexchanges"}
                count={dataflowReport.withoutDataExchangesCount}
                total={dataflowReport.totalCount}
                percentage={dataflowReport.withoutDataExchangesPercentage}
                nodeTypeName={NodeType.DataFlow.description}
            />}
            {!errorLoading && <ReportPercentFragment
                testId={"dataexchanges-without-middleware"}
                label={"% Dataexchanges Without Middlewares"}
                count={dataexchangeReport.withoutSupportingMiddlewareCount}
                total={dataexchangeReport.totalCount}
                percentage={dataexchangeReport.withoutSupportingMiddlewarePercentage}
                nodeTypeName={NodeType.DataExchange.description}
            />}
        </div>
    </div>
}
