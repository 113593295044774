import cssStyles from "./CountReport.module.css"
import {useEffect, useState} from "react";
import {addAuthorizationHeader} from "../../../utils/Api";
import ReportCounterFragment from "./ReportFragments/ReportCounterFragment";
import ReportMenuBar from "./ReportMenuBar";
import {REPORTS} from "./ReportConstants";
import {NodeType} from "../../../model/Constants";
import ErrorBox from "../../ErrorBox/ErrorBox";
import {Link} from "../QuickTutorial/Link";
import {useSubscriptionContext} from "../../../subscription/SubscriptionContext";

export default function CountReport({workspaceId, user}) {

    //const [actorCount, setActorCount] = useState("")
    const [applicationCount, setApplicationCount] = useState("")
    //const [architectureBuildingBlockCount, setArchitectureBuildingBlockCount] = useState("")
    //const [businessProcessCount, setBusinessProcessCount] = useState("")
    const [capabilityCount, setCapabilityCount] = useState("")
    const [dataExchangeCount, setDataExchangeCount] = useState("")
    const [dataFlowCount, setDataFlowCount] = useState("")
    const [dataObjectCount, setDataObjectCount] = useState("")
    const [designDecisionCount, setDesignDecisionCount] = useState("")
    //const [domainCount, setDomainCount] = useState("")
    //const [functionalityCount, setFunctionalityCount] = useState("")
    //const [scenarioCount, setScenarioCount] = useState("")
    const [middlewareCount, setMiddlewareCount] = useState("")
    const [principleCount, setPrincipleCount] = useState("")
    const [applicationViewCount, setApplicationViewCount] = useState("")
    const [talCount, setTalCount] = useState("")
    const [errorLoading, setErrorLoading] = useState(false)

    const {subscription} = useSubscriptionContext()



    useEffect(()=>{
        fetch("/api/report-counts", user && {
            // only attach headers if user is logged in, but still make the request regardless
            headers: addAuthorizationHeader({}, workspaceId, user)
        })
            .then(res =>{
                if (res.status === 200) {
                    return res.json()
                } else {
                    throw new Error("Error fetching report count data")
                }
            })
            .then(data=>{
                console.debug("report count data:", data)

                let pro = (subscription === "PRO")

                //setActorCount(data.actorCount)
                setApplicationCount(data?.totalCounts?.application)
                //setArchitectureBuildingBlockCount(data.architectureBuildingBlockCount)
                //setBusinessProcessCount(data.businessProcessCount)
                setCapabilityCount(data?.totalCounts?.capability)
                setDataExchangeCount(data?.totalCounts?.data_exchange)
                setDataFlowCount(data?.totalCounts?.data_flow)
                setDataObjectCount(data?.totalCounts?.data_object)
                if (pro) {
                    setDesignDecisionCount(data?.totalCounts?.design_decision)
                }
                //setDomainCount(data.domainCount)
                //setFunctionalityCount(data.functionalityCount)
                setMiddlewareCount(data?.totalCounts?.middleware)
                if (pro) {
                    setPrincipleCount(data?.totalCounts?.principle)
                }
                //setScenarioCount(data.scenarioCount)
                setTalCount(data?.totalCounts?.tal)
                setApplicationViewCount(data?.totalCounts?.application_view)
            })
            .catch((e)=>{
                console.error("Error fetching capability analysis: ", e)
                setErrorLoading(true)
            })
    }, [workspaceId, user, subscription])

    return <div className={cssStyles.main}>
        <ReportMenuBar
            user={user}
            report={REPORTS.countReport}
        />
        {
            errorLoading && <ErrorBox isCloseable={false} id={"countreport-errorbox"}>
                Error loading report data. If the error persists, please <Link type={"error"} href={"https://docs.kenchiku.io/support/tickets/new"}>contact support</Link> .
            </ErrorBox>
        }
        <div className={cssStyles.report}>

            {
                !errorLoading && <>

                    <ReportCounterFragment
                        testId={"applications-counter"}
                        label={"# applications"}
                        value={applicationCount}
                        nodeTypeName={NodeType.Application.description}
                    />

                    <ReportCounterFragment
                        testId={"capabilities-counter"}
                        label={"# capabilities"}
                        value={capabilityCount}
                        nodeTypeName={NodeType.Capability.description}
                    />

                    <ReportCounterFragment
                        testId={"data-exchanges-counter"}
                        label={"# data exchanges"}
                        value={dataExchangeCount}
                        nodeTypeName={NodeType.DataExchange.description}
                    />

                    <ReportCounterFragment
                        testId={"data-flows-counter"}
                        label={"# data flows"}
                        value={dataFlowCount}
                        nodeTypeName={NodeType.DataFlow.description}
                    />

                    <ReportCounterFragment
                        testId={"data-objects-counter"}
                        label={"# data objects"}
                        value={dataObjectCount}
                        nodeTypeName={NodeType.DataObject.description}
                    />
                    {subscription === "PRO" && <ReportCounterFragment
                        testId={"design-decision-counter"}
                        label={"# design decisions"}
                        value={designDecisionCount}
                        nodeTypeName={NodeType.DesignDecision.description}
                        isPro={true}
                    />}

                    <ReportCounterFragment
                        testId={"middlewares-counter"}
                        label={"# middlewares"}
                        value={middlewareCount}
                        nodeTypeName={NodeType.Middleware.description}
                    />

                    {subscription === "PRO" && <ReportCounterFragment
                        testId={"principle-counter"}
                        label={"# principles"}
                        value={principleCount}
                        nodeTypeName={NodeType.Principle.description}
                        isPro={true}
                    />}

                    <ReportCounterFragment
                        testId={"tals-counter"}
                        label={"# TALs"}
                        value={talCount}
                        nodeTypeName={NodeType.Tal.description}
                    />

                    <ReportCounterFragment
                        testId={"views-counter"}
                        label={"# views"}
                        value={applicationViewCount}
                        nodeTypeName={NodeType.ApplicationView.description}
                    />
                </>
            }
        </div>

    </div>
}
