import cssStyles from "./ClickableInlineObjectList.module.css"
import {useModel} from "../../model/ModelContext";
import {useSelectedNodes} from "../SelectedNodes/SelectedNodesProvider";
import {NODE_FILL_COLORS} from "../../utils/NodeTypeColors";
import Logger from "../../utils/Logger";
import {ClickableInlineObject} from "./ClickableInlineObject";

const LOGGER = new Logger("ClickableInlineObjectList")

export function ClickableInlineObjectList({listName="unknown", idList}) {
    const {getNodeById} = useModel()

    if (!idList) {
        LOGGER.warn("idList is null for listName: ", listName)
        return <></>
    }

    let objectList = idList?.map(id => getNodeById(id))

    if (!objectList) {
        LOGGER.warn("objectList is null for listName: ", listName)
        return <></>
    }

    return (
        objectList.map((object, index) => {
            if (!object) {
                LOGGER.warn("object passed is null for listName: ", listName)
                return <></>
            }

            return <ClickableInlineObject tagKey={listName + "-" + index} objectId={object.id} />
        })
    )
}
