import {NodeType} from "../../../model/Constants";
import cssStyles from "./ApplicationDiagram3.module.css";
import Logger from "../../../utils/Logger";
import {useEffect, useRef} from "react";
import * as jointjs from "@joint/plus";
import {getCustomProp} from "./utils/JointjsUtils";
import {useModel} from "../../../model/ModelContext";
import {useSelectedNodes} from "../../SelectedNodes/SelectedNodesProvider";
import {createApplicationRectangle} from "./ApplicationDiagram3_ShapeFactory";

const LOGGER = new Logger("ApplicationDiagram3")

const blockWidth = 100
const blockHeight = 100

const xPadding = 100

const sourceApplicationStartY = 0
const nodeApplicationStartY = 250
const targetApplicationStartY = 500

function getWrappedTextHeight(text, font, maxWidth) {
    // Create a temporary element
    var tempDiv = document.createElement('div');
    tempDiv.style.position = 'absolute';
    tempDiv.style.visibility = 'hidden';
    tempDiv.style.width = maxWidth + 'px'; // Set the width to the maximum width of your label
    tempDiv.style.font = font; // Set the font properties as your label
    tempDiv.textContent = text;

    // Append to the body and measure height
    document.body.appendChild(tempDiv);
    var height = tempDiv.offsetHeight;

    // Clean up
    document.body.removeChild(tempDiv);

    return height;
}



function createDataExchangeLink(startApplicationRectangle, stopApplicationRectangle, dataExchanges, labelAtBeginning) {

    let linkOptions = {
        source: {
            id: startApplicationRectangle.id,
            anchor: {
                name: 'center'
            }
        },
        target: {
            id: stopApplicationRectangle.id,
            anchor: {
                name: 'center'
            }
        },
        //router: ARROW_TYPES["grid"]?.router,
        //connector: ARROW_TYPES["grid"]?.connector,
        line: {
            stroke: 'black',
            strokeWidth: 2,
            targetMarker: {
                type: 'path',
                d: 'M 10 -5 0 0 10 5 z'
            }
        },
        vertices: ([]),
    };

    let link = new jointjs.shapes.standard.Link(linkOptions);
    let size = dataExchanges.length
    let labelText = dataExchanges.map((df)=>(size>1?"* ":"") + df.name).join("\n")

    const labelHeight = getWrappedTextHeight(
        labelText,
        "10px Arial",
        120)

    let position = {
        distance: 0,
        offset: {
            x: 0,
            y: 25,
        },
    }
    if (!labelAtBeginning) {
        position = {
            distance: 1,
            offset: {
                x: 0,
                y: -labelHeight/2-25,
            },
        }
    }

    link.appendLabel({
        position,
        attrs: {
            text: {
                text: labelText,
                fontSize: 10,
                fontFamily: 'Arial',
                width: 120,
                textWrap: {
                    width: 120,
                    height: 100,
                    ellipsis: true,
                }
            }
        }
    })

    return link
}

export default function ApplicationDiagram3({node}) {

    const {searchNodes, getNodeById} = useModel()
    const {selectedNodes, softSelectedNodes} = useSelectedNodes()

    const namespace = jointjs.shapes

    const paperRef = useRef()

    const graph = useRef(null)
    const paper = useRef(null)
    const scroller = useRef(null)


    useEffect(()=> {

        if (!paperRef.current) {
            LOGGER.debug("no paperRef.current")
            return
        }

        LOGGER.debug("Creating new Graph instance")
        graph.current = new jointjs.dia.Graph({}, {cellNamespace: namespace})


        //avoid embedded elements from being moved separately from their parent
        //kudos to: https://stackoverflow.com/a/56113520/425677
        const interactive = function (cellView, eventString) {
            const isLocked = getCustomProp(cellView.model, "locked")
            if (isLocked) {
                return {
                    elementMove: false
                };
            }
            if (!cellView.model.isLink()) {
                return {
                    linkMove: false,
                    labelMove: true,
                    arrowheadMove: false,
                    vertexMove: false,
                    vertexAdd: false,
                    vertexRemove: false,
                    useLinkTools: false,
                }
            }
            if (cellView.model.isEmbedded()) {
                return {
                    elementMove: false,
                };
            }

            return true;
        }
        paper.current = new jointjs.dia.Paper({
            foreignObjectRendering: true,
            snapLabels: true,
            model: graph.current,
            drawGrid: true,
            cellViewNamespace: namespace,
            interactive: interactive,
            autoResizePaper: true,
            cursor: 'grab',
            scrollWhileDragging: true,
            inertia: true,
            connectionStrategy: jointjs.connectionStrategies.pinAbsolute,

        });

        scroller.current = new jointjs.ui.PaperScroller({
            paper: paper.current,
            autoResizePaper: true,
            cursor: 'grab',

        });

        paper.current.on('blank:pointerdown', scroller.current.startPanning);

        paperRef.current.appendChild(scroller.current.el);
        scroller.current.render().center();

        return () => {
            scroller.current.remove();
            paper.current.remove();
        };

    }, [paperRef, namespace])

    useEffect(()=>{

        const selectedNodeIds = selectedNodes.map((n)=>n.id)
        const softSelectedNodeIds = softSelectedNodes.map((n)=>n.id)


        graph.current.clear()

        const dataExchanges = searchNodes((n)=>{
            return (n.type === NodeType.DataExchange.description)
        }).filter((n)=>{
            return (n.sourceApplicationId === node.id || n.targetApplicationId === node.id)
        })

        const sourceApplicationMap = new Map()
        const targetApplicationMap = new Map()

        dataExchanges.forEach((de)=>{

            //add to sourceApplicationMap

            if (de.targetApplicationId === node?.id) {
                let sourceDataexchanges = sourceApplicationMap.get(de.sourceApplicationId)

                if (sourceDataexchanges) {
                    sourceDataexchanges.sourceDfArray.push(de)
                } else {
                    sourceDataexchanges = {
                        sourceApplicationId: de.sourceApplicationId,
                        sourceDfArray: [de]
                    }
                    sourceApplicationMap.set(de.sourceApplicationId, sourceDataexchanges)
                }
            }

            //add to targetApplicationMap
            if (de.sourceApplicationId === node?.id) {
                let targetDataexchanges = targetApplicationMap.get(de.targetApplicationId)

                if (targetDataexchanges) {
                    targetDataexchanges.targetDfArray.push(de)
                } else {
                    targetDataexchanges = {
                        targetApplicationId: de.targetApplicationId,
                        targetDfArray: [de]
                    }
                    targetApplicationMap.set(de.targetApplicationId, targetDataexchanges)
                }
            }

        })
        const numberOfSources = sourceApplicationMap.size
        const numberOfTargets = targetApplicationMap.size
        const sourceApplicationRectanglesWidth = numberOfSources * blockWidth + (numberOfSources-1) * xPadding
        const targetApplicationRectanglesWidth = numberOfTargets * blockWidth + (numberOfTargets-1) * xPadding

        const middleX = 200

        const sourceApplicationStartX = middleX - sourceApplicationRectanglesWidth/2
        const nodeApplicationStartX = middleX - blockWidth/2
        const targetApplicationStartX = middleX - targetApplicationRectanglesWidth/2

        let sourceCounter = 0
        let targetCounter = 0

        const nodeApplicationLabel = node?.name
        const nodeApplicationRectangle = createApplicationRectangle(
            blockWidth,
            blockHeight,
            nodeApplicationStartX,
            nodeApplicationStartY,
            blockWidth,
            blockHeight,
            nodeApplicationLabel,
            selectedNodeIds.includes(node?.id),
            softSelectedNodeIds.includes(node?.id)
        )
        graph.current.addCell(nodeApplicationRectangle)

        sourceApplicationMap.forEach((sourceApplication, sourceApplicationId)=> {
            const sourceDataExchanges = sourceApplication.sourceDfArray
            const sourceApplicationNode = getNodeById(sourceApplicationId)
            const sourceApplicationLabel = sourceApplicationNode?.name
            const sourceApplicationRectangle = createApplicationRectangle(
                blockWidth,
                blockHeight,
                sourceApplicationStartX + sourceCounter * (blockWidth + xPadding),
                sourceApplicationStartY,
                blockWidth,
                blockHeight,
                sourceApplicationLabel,
                selectedNodeIds.includes(sourceApplicationId),
                softSelectedNodeIds.includes(sourceApplicationId)
            )
            graph.current.addCell(sourceApplicationRectangle)
            sourceCounter++

            const link = createDataExchangeLink(sourceApplicationRectangle, nodeApplicationRectangle, sourceDataExchanges, true)
            graph.current.addCell(link)
        })


        targetApplicationMap.forEach((targetApplication, targetApplicationId)=> {
            const targetDataExchanges = targetApplication.targetDfArray
            const targetApplicationNode = getNodeById(targetApplicationId)
            const targetApplicationLabel = targetApplicationNode?.name
            const targetApplicationRectangle = createApplicationRectangle(
                blockWidth,
                blockHeight,
                targetApplicationStartX + targetCounter * (blockWidth + xPadding),
                targetApplicationStartY,
                blockWidth,
                blockHeight,
                targetApplicationLabel,
                selectedNodeIds.includes(targetApplicationId),
                softSelectedNodeIds.includes(targetApplicationId)
            )
            graph.current.addCell(targetApplicationRectangle)
            targetCounter++

            const link = createDataExchangeLink(nodeApplicationRectangle, targetApplicationRectangle, targetDataExchanges, false)
            graph.current.addCell(link)
        })

        // eslint-disable-next-line
    }, [node, selectedNodes, softSelectedNodes])

    return <div ref={paperRef} className={cssStyles.main}>
    </div>
}
