import {addAuthorizationHeader} from "../../../../utils/Api";
import Logger from "../../../../utils/Logger";

const LOGGER = new Logger("ScenarioGenerators")

export let listGenerator = (user, listDescription, maxItems=5, maxItemLength=30) => {
    return fetch("/api/generate-list", {
        method: "POST",
        headers: addAuthorizationHeader({}, null, user),
        body: JSON.stringify({
            listDescription, maxItems, maxItemLength
        })
    }).then((response) => {
        LOGGER.debug("response: ", response);
        let json = response.json();
        return json;
    }).then((data) => {
        LOGGER.debug("got response from generator: ", data);
        return {success: true, data: data};
    }).catch((err) => {
        LOGGER.error("Error: ", err);
        return {success: false, message: err};
    })
}
