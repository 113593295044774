import {
    getNodeCollectionName,
    getNodeTypeFields,
    getNodeTypeRootFolderId,
    NodeFolderRootIds,
} from "./model/Constants";
import {addAuthorizationHeader} from "./utils/Api";
import Logger from "./utils/Logger";

const LOGGER = new Logger("DataLoader", 2)

function getParentIdSafely(d, typeRootId) {
    let parentId = (d.parentId ? d.parentId : typeRootId)
    if (parentId === d.id) {
        LOGGER.warn("Careful, parentId === d.id; d=", d)
        parentId = typeRootId
    }
    return parentId
}

async function fetchNodes(workspaceId, user) {

    if (!workspaceId) {
        LOGGER.debug("missing workspaceId")
        return []
    }

    if (!user) {
        LOGGER.debug("missing user")
        return []
    }


    return fetch(
        "/api/manage-model-objects", workspaceId && user && {
        // only attach headers if user is logged in, but still make the request regardless
        headers: addAuthorizationHeader({}, workspaceId, user)
    }).then(res => {
        return res.json()
    }).then(data => {
        LOGGER.debug("folders data: ", data)
        const newNodes = []
        data.forEach((d) => {
            //LOGGER.debug("folders:", d)
            const nodeTypeString = d.type
            const rootIdString = getNodeTypeRootFolderId(nodeTypeString)

            let parentId = getParentIdSafely(d, rootIdString)
            let fields = getNodeTypeFields(nodeTypeString)

            const newNode = {
                type: nodeTypeString
            }
            fields.forEach((f) => {
                newNode[f] = d[f]
            })
            newNode.parentId = parentId
            newNode.owner_id = d?.owner_id
            newNodes.push(newNode)
        })
        return newNodes
    }).catch((e) => {
        LOGGER.error("error fetching nodes':", e)
    })
}

export default class DataLoader {

    isLoading = false
    cachedData = {}

    async fetchWorkspaces(user, successHandler, errorHandler) {
        LOGGER.debug("fetch workspaces, with user:", user)
        const onErrorHandler = errorHandler || function(e) {LOGGER.error("error fetching 'workspaces':", e)}
        return await fetch(
            "/api/manage-workspaces",  user && {
            // only attach headers if user is logged in, but still make the request regardless
            headers: addAuthorizationHeader({}, null, user)
        }).then(res => {

            LOGGER.debug("fetchWorkspaces.fetch.result:", res)
            return res.json()
        }).then(data => {
            LOGGER.debug("Workspaces data: ", data)
            successHandler(data)
            return data
        }).catch((e) => {
            onErrorHandler(e)
        })
    }

    async fetchNodes(workspaceId, user, nodeTypeString, onStartLoading, onDoneLoading) {

        this.loadData(
            workspaceId,
            user,
            ()=>onStartLoading(),
            (result)=>{
                let nodeOfSpecificTypes = result?.nodes?.filter(n=>n.type === nodeTypeString)
                onDoneLoading(nodeOfSpecificTypes)
            }
        )

    }

    async removeNodes(workspaceId, user, nodes, startRemoving, nodeRemoved, doneRemoving) {
        startRemoving(nodes)

        let calls = nodes.map((node) => {
            LOGGER.debug("node to remove:", node)
            let endpoint = "/api/manage-model-objects"

            return fetch(endpoint + "?id=" + node.id, {
                headers: addAuthorizationHeader({}, workspaceId, user),
                method: "DELETE"
            }).then(res =>
                res.json()
            ).then(capa => {
                LOGGER.debug("deleted object: ", node)
                nodeRemoved()
            }).catch(e => {
                LOGGER.error("Error POSTing capability: ", e)
            })
        })
        await Promise.all(calls)
        this.cachedData = {}
        if (doneRemoving) {
            doneRemoving()
        }
    }
    async saveNode(workspaceId, user, node, onStartSave, onStopSave, onError) {

        //TODO save the node
        if (0 === node?.id?.indexOf("_")) {
            LOGGER.warn("someone tried saving an object with internal id (i.e., starting with '_'. refused.")
            return
        }

        if (node && node?.id === node?.parentId) {
            LOGGER.warn("someone tried to save a object with its ID as its parentId -> circular. not good. refusing to save...")
            node.parentId = getParentIdSafely(node, getNodeCollectionName(node.type))
            LOGGER.info("Corrected parentId before saving node. node.parentId: ", node.parentId)
        }

        if (onStartSave) {
            onStartSave(node)
        }

        LOGGER.debug("saveObject.node:", node)
        LOGGER.trace("", node)

        let endpoint = "/api/manage-model-objects"
        LOGGER.debug("saveObject.node_type:", node.type)
        LOGGER.debug("saveObject.endpoint:", endpoint)

        let bodyString = ""
        try {
            LOGGER.debug(`stringifying object`)
            bodyString = JSON.stringify(node)
            LOGGER.debug(`stringified object: ${bodyString}.`)
        } catch (e) {
            LOGGER.error("Error stringifying object: ", node)
            LOGGER.error("Error stringifying object: ", e)
            onError("An error occurred while saving...", e)
        }

        const savedNode = await fetch(endpoint,{
            headers: addAuthorizationHeader({
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }, workspaceId, user),
            method: "POST",
            body: bodyString
        }).then(res => {
            LOGGER.debug("Got response")
            if (!res.ok) {
                res.json().then((jsonData) => {
                    onError(`${jsonData}`)
                })
                return null
            } else {
                return res.json()
            }
        }).then(obj=> {
            LOGGER.debug("received post result")
            LOGGER.trace("obj: ", obj)
            return obj
        }).catch(e => {
            LOGGER.error("Error POSTing object: ", e)
            if (onError) {
                onError("An error occurred while saving...", e)
                return
            }
        })
        this.cachedData = {}
        if (onStopSave) {
            //todo not very happy with the way we handle "owner_id" here... but it works for now
            onStopSave({...savedNode, owner_id: workspaceId})
        }
        return {...savedNode, owner_id: workspaceId}

    }
    async loadData(workspaceId, user, startLoading, doneLoading, errorLoading) {

        if (this.isLoading) {
            LOGGER.debug("already loading")
            return
        }

        //LOGGER.debug("loadData.workspaceId: ", workspaceId)
        //LOGGER.debug("loadData.user: ", user)

        if (workspaceId === undefined || workspaceId === "undefined") {
            LOGGER.trace("workspaceId === undefined")
            doneLoading(undefined)
            return
        }

        if (user === undefined) {
            LOGGER.trace("user === undefined")
            doneLoading(undefined)
            return
        }

        startLoading()

        let newNodes = []

        if (this.cachedData[workspaceId]) {
            LOGGER.debug("there is cached data: ", this.cachedData[workspaceId])
            if (doneLoading) {
                doneLoading(this.cachedData[workspaceId])
            }
            return
        }

        try {


            this.isLoading = true

            LOGGER.trace("adding root nodes")


            //fetch all nodes
            const allNodes = await fetchNodes(workspaceId, user)
            newNodes = newNodes.concat(allNodes)

            //add the elements at the start of the array

            let viewsFolder = {
                id: NodeFolderRootIds.ApplicationViewRootId.description,
                name: "Application Views",
                type: "folder",
            };
            let talsFolder = {
                id: NodeFolderRootIds.TalRootId.description,
                name: "TALs",
                type: "folder",
            };
            let scenariosFolder = {
                id: NodeFolderRootIds.ScenarioRootId.description,
                name: "Scenarios",
                type: "folder",
            };
            let domainsFolder = {
                id: NodeFolderRootIds.DomainRootId.description,
                name: "Domains",
                type: "folder",
            };
            let actorsFolder = {
                id: NodeFolderRootIds.ActorRootId.description,
                name: "Actors",
                type: "folder",
            };
            let dataExchangesFolder = {
                id: NodeFolderRootIds.DataExchangeRootId.description,
                name: "Data Exchanges",
                type: "folder"
            }
            let dataFlowsFolder = {
                id: NodeFolderRootIds.DataFlowRootId.description,
                name: "Data Flows",
                type: "folder",
            };
            let dataObjectsFolder = {
                id: NodeFolderRootIds.DataObjectRootId.description,
                name: "Data Objects",
                type: "folder",
            };
            let capabilitiesFolder = {
                id: NodeFolderRootIds.CapabilityRootId.description,
                name: "Capabilities",
                type: "folder",
            }
            let businessProcessesFolder = {
                id: NodeFolderRootIds.BusinessProcessRootId.description,
                name: "Business Processes",
                type: "folder",
            }
            let functionalitiesFolder = {
                id: NodeFolderRootIds.FunctionalityRootId.description,
                name: "Functionalities",
                type: "folder",
            }
            let applicationsFolder = {
                id: NodeFolderRootIds.ApplicationRootId.description,
                name: "Applications",
                type: "folder",
            }
            let maturityModelAnalysesFolder = {
                id: NodeFolderRootIds.MaturityModelAnalysisRootId.description,
                name: "Maturity Model Analyses",
                type: "folder",
            }
            let middlewaresFolder = {
                id: NodeFolderRootIds.MiddlewareRootId.description,
                name: "Middlewares",
                type: "folder",
            }
            let architectureBuildingBlockFolder = {
                id: NodeFolderRootIds.ArchitectureBuildingBlockRootId.description,
                name: "Architecture Building Blocks",
                type: "folder",
            }
            let referenceArchitectureFolder = {
                id: NodeFolderRootIds.ReferenceArchitectureRootId.description,
                name: "Reference Architecture",
                type: "folder",
            }
            let snapshotsFolder = {
                id: NodeFolderRootIds.SnapshotRootId.description,
                name: "Snapshots",
                type: "folder",
            };
            let principlesFolder = {
                id: NodeFolderRootIds.PrincipleRootId.description,
                name: "Principles",
                type: "folder",
            }
            let designDecisionsFolder = {
                id: NodeFolderRootIds.DesignDecisionRootId.description,
                name: "Design Decisions",
                type: "folder",
            }
            let technologiesFolder = {
                id: NodeFolderRootIds.TechnologyRootId.description,
                name: "Technologies",
                type: "folder",
            }

            newNodes.push(viewsFolder)
            newNodes.push(talsFolder)
            newNodes.push(scenariosFolder)
            newNodes.push(referenceArchitectureFolder)
            newNodes.push(architectureBuildingBlockFolder)
            newNodes.push(domainsFolder)
            newNodes.push(actorsFolder)
            newNodes.push(capabilitiesFolder)
            newNodes.push(businessProcessesFolder)
            newNodes.push(functionalitiesFolder)
            newNodes.push(applicationsFolder)
            newNodes.push(dataFlowsFolder)
            newNodes.push(dataObjectsFolder)
            newNodes.push(dataExchangesFolder)
            newNodes.push(snapshotsFolder)
            newNodes.push(middlewaresFolder)
            newNodes.push(maturityModelAnalysesFolder)
            newNodes.push(principlesFolder)
            newNodes.push(designDecisionsFolder)
            newNodes.push(technologiesFolder)

            //LOGGER.debug("ALL_data:", allData)

            this.cachedData[workspaceId] = {nodes: newNodes}
            LOGGER.debug("caching data")
            LOGGER.trace("caching data: ", this.cachedData)
        } catch (e) {
            LOGGER.error("error loading data: ", e)
            errorLoading(e)
        } finally {
            this.isLoading = false
            if (doneLoading) {
                doneLoading(this.cachedData[workspaceId])
            }
        }
    }

}
