import cssStyles from "./MultilineTextField.module.css"
import {Button, LinearProgress, TextField} from "@mui/material"
import {forwardRef, useState} from "react";
import Logger from "../../../../utils/Logger";
import {showDialog} from "../../diagrams/utils/JointjsUtils";

const LOGGER = new Logger("MultilineTextField")

function MultilineTextField({onChange, onBlur, name="description", setValue, //comes through register
                                       error, helperText, id="description", label="Description", minRows=5, maxRows=5, generator=false}, ref) {

    const [isGenerating, setIsGenerating] = useState(false)

    const handleGenerate = async () => {
        if (generator) {
            LOGGER.debug("generator is enabled")
            setIsGenerating(true)
            try {
                const response = await generator()
                LOGGER.debug("responseString: ", response)
                if (response.success) {
                    ref.current.value = response.data
                } else {
                    showDialog({
                        text: "Error generating text: " + response.message,
                        buttons: [{
                            id: "close",
                            text: "Close",
                            handler: () => {
                            },
                            sameAsClose: true,
                        }],
                        selectOptions: [],
                        isMultiSelect: false,
                    })
                    return
                }
            } finally {
                setIsGenerating(false)
            }
        } else {
            LOGGER.debug("generator is not enabled")
        }
    }

    return (
        <div className={cssStyles.mainDiv}>
            <TextField
                ref={ref}   //this is required for the register to work
                multiline
                minRows={minRows}
                maxRows={maxRows}
                margin="dense"
                id={id}
                name={name}
                label={label}
                type="text"
                fullWidth
                variant="standard"
                onChange={onChange}
                onBlur={onBlur}
                error={error}
                helperText={helperText}
            />
            {isGenerating && <span className={cssStyles.progressSpan}><LinearProgress/></span>}
            {generator && !isGenerating && <span className={cssStyles.buttonSpan}><Button className={cssStyles.button} disabled={isGenerating} onClick={() => {
                handleGenerate()
            }}>{((generator)?"Generate":"")}</Button></span> }
        </div>
    )
}

export default forwardRef(MultilineTextField)
