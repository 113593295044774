import cssStyles from "./DiagramPane.module.css"
import {NodeType} from "../../../model/Constants"
import Logger from "../../../utils/Logger";
import {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import {FolderDiagram2} from "./FolderDiagram2";
import ApplicationDiagram3 from "./ApplicationDiagram3";
import TalDiagram3 from "./TalDiagram3";
import {safeStringify} from "../../../utils/JSONUtils";
import CapabilityDiagram2 from "./CapabilityDiagram2";
import DataObjectDiagram from "./DataObjectDiagram";
import DataExchangeDiagram from "./DataExchangeDiagram";
import HelpArrow from "../../../utils/HelpArrow";
import MaturityModelDiagram from "./MaturityModelDiagram/MaturityModelDiagram";
import DesignDecisionDiagram from "./DesignDecisionDiagram";
import PrincipleDiagram from "./PrincipleDiagram";
import {useWorkspaceContext} from "../../../security/WorkspaceContext";
import {useSecurityContext} from "../../../security/SecurityContext";
import {useSelectedNodes} from "../../SelectedNodes/SelectedNodesProvider";
import {useModel} from "../../../model/ModelContext";
import DataFlowDiagram from "./DataFlowDiagram";
import MiddlewareDiagram from "./MiddlewareDiagram";
import BusinessProcessDiagram from "./BusinessProcessDiagram";
import ActorDiagram from "./ActorDiagram";
import ScenarioDiagram from "./ScenarioDiagram";
import ApplicationViewDiagram2 from "./ApplicationViewDiagram2";

const LOGGER = new Logger("DiagramPane", 1)

function DiagramPane({
                         isPlaywrightMouseDownOnNode
                     }, ref) {

    const {selectedWorkspace} = useWorkspaceContext()
    const {user} = useSecurityContext()
    const {selectedNodes} = useSelectedNodes()
    const {saveNode} = useModel()

    const [node, setNode] = useState({})

    /*
     if the selectNodes array has a length of 1, then the node is the first element in the array
     */
    useEffect(()=>{
        if (selectedNodes?.length >= 1) {
            if (selectedNodes[0].id !== node.id) {
                LOGGER.debug("DiagramPane.useEffect.selectedNodes[0]:", selectedNodes[0].name)
                setNode(selectedNodes[0])
            }
        }
    }, [node, selectedNodes])


    const jsonString = safeStringify(node, "node is probably circular")

    LOGGER.trace("DiagramPane.node (stringlength=" + jsonString?.length + "):", jsonString?.substring(0, 400))

    const applicationViewDiagramRef = useRef()
    const talDiagramRef = useRef()

    const [diagram, setDiagram] = useState("")

    useImperativeHandle(ref,()=>{
        return ({
            clearDiagram: ()=>{
                LOGGER.debug("cleardiagram called")

            }
        })
    })

    useImperativeHandle(ref,()=>{
        return ({
            addNodeById: (nodeId, x, y)=>{
                LOGGER.debug("addNodeById.nodeId: ", nodeId)
                if (diagram && diagram.ref && diagram.ref.current && diagram.ref.current.addNodeById) {
                    diagram.ref.current.addNodeById(nodeId, x, y)
                } else {
                    LOGGER.debug("addNodeById not found")
                }
            }
        })
    }, [diagram])

    useEffect(()=>{
        LOGGER.trace("selectedNodes:", selectedNodes.map(n=>n.name))
    }, [selectedNodes])

    useEffect(()=>{
        LOGGER.trace("DiagramPane.setting diagram")

        if (!node?.id?.startsWith("_") && node.owner_id !== selectedWorkspace) {
            LOGGER.debug("DiagramPane.useEffect.node, workspaceId:", selectedWorkspace)
            setDiagram(<>
                <div
                    id={"select-a-folder-or-node-div"}
                    className={cssStyles.selectAFolderOrNodeDiv}
                >
                    <span id={"select-a-folder-or-node-span"}>&nbsp;&nbsp;Select a folder or node in the Tree Navigator to see its diagram or grid here.</span>
                </div>
                <HelpArrow
                    startId={"select-a-folder-or-node-span"}
                    endId={"tree-navigator"}
                />
            </>)
            return
        }

        switch(node?.type) {
            case NodeType.Actor.description:
                setDiagram(<ActorDiagram
                    node={node}
                />)
                break
            case NodeType.Application.description:
                setDiagram(<ApplicationDiagram3
                    node={node}
                />)
                break
            case NodeType.ApplicationView.description:
                setDiagram(<ApplicationViewDiagram2
                    ref={applicationViewDiagramRef}
                    node={node}
                />)
                break
            case NodeType.BusinessProcess.description:
                setDiagram(<BusinessProcessDiagram
                    node={node}
                />)
                break
            case NodeType.Capability.description:
                setDiagram(<CapabilityDiagram2
                    node={node}
                />)
                //setDiagram(<CapabilityMapDiagram/>)
                break
            case NodeType.DataExchange.description:
                setDiagram(<DataExchangeDiagram

                />)
                //setDiagram(<CapabilityMapDiagram/>)
                break
            case NodeType.DataFlow.description:
                setDiagram(<DataFlowDiagram
                    node={node}
                />)
                //setDiagram(<CapabilityMapDiagram/>)
                break
            case NodeType.DataObject.description:
                setDiagram(<DataObjectDiagram
                    node={node}
                />)
                //setDiagram(<CapabilityMapDiagram/>)
                break
            case NodeType.DesignDecision.description:
                setDiagram(<DesignDecisionDiagram
                    node={node}
                />)
                //setDiagram(<CapabilityMapDiagram/>)
                break
            case NodeType.Folder.description:
                setDiagram(<FolderDiagram2
                    node={node}
                />)
                break
            case NodeType.MaturityModelAnalysis.description:
                setDiagram(<MaturityModelDiagram
                    node={node}
                />)
                break
            case NodeType.Middleware.description:
                setDiagram(<MiddlewareDiagram
                    node={node}
                />)
                break
            case NodeType.Principle.description:
                setDiagram(<PrincipleDiagram
                    node={node}
                />)
                //setDiagram(<CapabilityMapDiagram/>)
                break
            case NodeType.Scenario.description:
                setDiagram(<ScenarioDiagram
                    node={node}
                />)
                break

            case NodeType.Tal.description:
                setDiagram(<TalDiagram3
                    ref={talDiagramRef}
                    onViewSave={saveNode}
                    showApplications={true}
                />)
                break
            default:
                //setDiagram(<b>no diagram for type '{node?.type}' yet.</b>)
                setDiagram(
                    <>
                        <div id={"select-a-folder-or-node-div"} className={cssStyles.selectAFolderOrNodeDiv}><span id={"select-a-folder-or-node-span"}>&nbsp;&nbsp;Select a folder or node in the Tree Navigator to see its diagram or grid here.</span></div>
                        <HelpArrow
                            startId={"select-a-folder-or-node-span"}
                            endId={"tree-navigator"}
                        />
                    </>
                )


                break
        }

    }, [saveNode, selectedWorkspace, user, selectedNodes,node])

    return <div className={cssStyles.mainDiv} ref={ref}>{diagram}</div>

}

export default forwardRef(DiagramPane)
