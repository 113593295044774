//https://rogulski.it/blog/react-material-checkbox-form-validation/
//https://www.youtube.com/watch?v=10Z0OCPfubI

import cssStyles from "./ScenarioEditor.module.css"

import {useEffect, useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from "@mui/material";
import NodeSelector from "./selectors/NodeSelector";

import {useForm} from "react-hook-form"
import {object, string, date, array} from 'yup';
import {useYupValidationResolver} from "./EditorUtils"
import Logger from "../../../utils/Logger"
import {NodeFolderRootIds, NodeType} from "../../../model/Constants";
import MultilineTextField from "./fields/MultilineTextField";
import {FullscreenButton} from "./buttons/FullscreenButton";
import {RichTextLabeler} from "./fields/RichTextLabeler";
import {useModel} from "../../../model/ModelContext";
import {useSubscriptionContext} from "../../../subscription/SubscriptionContext";
import {useSecurityContext} from "../../../security/SecurityContext";
import {BulletedTextField} from "./fields/BulletedTextField";
import {descriptionGenerator} from "./generators/GenericGenerators";
import {listGenerator} from "./generators/ScenarioGenerators";
import {addHtmlContentCheck} from "../../../utils/YupUtils";

const LOGGER = new Logger("ScenarioEditor")

addHtmlContentCheck()

const SCENARIO_SCHEMA = object().shape({
    //id: string().required(),
    name: string().required(),
    description: string().required().min(10).max(5000),
    parentId: string().required().default(NodeFolderRootIds.ScenarioRootId.description),
    createdOn: date().default(() => new Date()),
    updatedOn: date().default(() => new Date()),
    explanation: string().required().checkTextContentNotEmpty("Explanation cannot be empty"),
    advantages: array().of(string()).default([]),
    disadvantages: array().of(string()).default([]),
    newApplicationIds: array().of(string()).default([]),
    updatedApplicationIds: array().of(string()).default([]),
    deletedApplicationIds: array().of(string()).default([]),
});

const PARENT_NODE_LABEL = "Parent Scenario"
const PARENT_NODE_ROOT_ID = NodeFolderRootIds.ScenarioRootId.description
const PARENT_NODE_TYPE = NodeType.Scenario.description
const PARENT_NODE_TYPE_NAME = "Scenario"

const NEWAPPLICATIONS_NODE_LABEL = "New Applications"
const NEWAPPLICATIONS_NODE_ROOT_ID = NodeFolderRootIds.ApplicationRootId.description
const NEWAPPLICATIONS_NODE_TYPE = NodeType.Application.description
const NEWAPPLICATIONS_NODE_TYPE_NAME = "New Application"

const UPDATEDAPPLICATIONS_NODE_LABEL = "Updated Applications"
const UPDATEDAPPLICATIONS_NODE_ROOT_ID = NodeFolderRootIds.ApplicationRootId.description
const UPDATEDAPPLICATIONS_NODE_TYPE = NodeType.Application.description
const UPDATEDAPPLICATIONS_NODE_TYPE_NAME = "Updated Application"

const DELETEDAPPLICATIONS_NODE_LABEL = "Deleted Applications"
const DELETEDAPPLICATIONS_NODE_ROOT_ID = NodeFolderRootIds.ApplicationRootId.description
const DELETEDAPPLICATIONS_NODE_TYPE = NodeType.Application.description
const DELETEDAPPLICATIONS_NODE_TYPE_NAME = "Application"



async function createObject(id, name, description, parentId, explanation, advantages, disadvantages, newApplicationIds, updatedApplicationIds, deletedApplicationIds) {
    LOGGER.trace("createObject")
    let validationObject = {
        id,
        name,
        description,
        parentId,
        type: PARENT_NODE_TYPE,
        explanation,
        advantages,
        disadvantages,
        newApplicationIds,
        updatedApplicationIds,
        deletedApplicationIds
    }
    LOGGER.trace("validationObject: ", validationObject)
    const node = await SCENARIO_SCHEMA.validate(validationObject)
    LOGGER.trace("done validating, returning node:", node)
    return node
}

export default function ScenarioEditor({
                              isOpen,
                              titleOfForm,
                              descriptionOfForm,
                              node,
                              onClose,
                              onCancel
                          }) {

    const {user} = useSecurityContext()

    const [showFullscreen, setShowFullscreen] = useState(false)

    const {nodes} = useModel()

    const handleClose = async (data, reason) => {
        LOGGER.debug("data:", data)
        LOGGER.debug("reason:", reason)
        if (!data || (reason && ["escapeKeyDown", "backdropClick"].includes(reason))) {
            handleCancel()
            return
        }
        onClose(await createObject(
            data.id,
            data.name,
            data.description,
            data.parentId,
            data.explanation,
            data.advantages,
            data.disadvantages,
            data.newApplicationIds,
            data.updatedApplicationIds,
            data.deletedApplicationIds
        ))
    };

    const handleCancel = () => {
        onCancel(node.id)
    };

    const defaultValues = {
        id:node?.id,
        name: "[name]",
        description: "[description]",
        parentId: node?.parentId,
        explanation: "",
        advantages: [],
        disadvantages: [],
        newApplicationIds: [],
        updatedApplicationIds: [],
        deletedApplicationIds: []
    }

    const resolver = useYupValidationResolver(SCENARIO_SCHEMA);
    const {
        register,
        handleSubmit,
        formState: {errors},
        setValue,
        watch,
        control,
        getValues,
        trigger,
    } = useForm({
        resolver,
        defaultValues
    });

    useEffect(()=>{
        if (node) {
            Object.keys(node).forEach(k => setValue(k, node[k]))
        }
    }, [node, setValue])

    useEffect(()=>{
        LOGGER.debug("errors:", errors)
    }, [errors])

    const {subscription} = useSubscriptionContext()

    let theDescriptionGenerator = false
    let theAdvantagesGenerator = false
    let theDisadvantagesGenerator = false

    if (subscription === "PRO") {
        theDescriptionGenerator = ()=>descriptionGenerator(node, user)
        theAdvantagesGenerator = () => {
            let type = "advantages"
            let scenarioName = getValues("name")
            let scenarioDescription = getValues("description")
            let scenarioExplanation = getValues("explanation").replace('{', '').replace('}', '')
            const listDescription = `List of ${type} of a scenario called '${scenarioName}' and described as '${scenarioDescription}' and explained as '${scenarioExplanation}'.`
            return listGenerator(
                user,
                listDescription
            ).then((response) => {
                if (response.success) {
                    const advantages = response.data
                    LOGGER.debug("advantages: ", advantages)
                    setValue("advantages", advantages)
                }

            })


        }
        theDisadvantagesGenerator = () => {
            let type = "disadvantages"
            let scenarioName = getValues("name")
            let scenarioDescription = getValues("description")
            let scenarioExplanation = getValues("explanation")
            const listDescription = `List of ${type} of a scenario called '${scenarioName}' and described as '${scenarioDescription}' and explained as '${scenarioExplanation}'.`
            return listGenerator(
                user,
                listDescription
            ).then((response) => {
                if (response.success) {
                    const disadvantages = response.data
                    LOGGER.debug("disadvantages: ", disadvantages)
                    setValue("disadvantages", disadvantages)
                }

            })
        }
    }


    useEffect(() => {
        LOGGER.debug("errors:", errors)
    }, [errors]);

    //Object.keys(node).forEach(k=>setValue(k, node[k]))      //too many rerenders CAUSE

    return (
        <div>
            <Dialog open={isOpen} onClose={()=>{handleClose()}} fullScreen={showFullscreen}>
                <DialogTitle className={cssStyles.dialogTitle}>
                    <span>{titleOfForm}</span>
                    <div className={cssStyles.fullscreenButton}>
                        <FullscreenButton
                            onFullscreen={()=>setShowFullscreen(true)}
                            onExitFullscreen={()=>setShowFullscreen(false)}
                        />
                    </div>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {descriptionOfForm}
                    </DialogContentText>
                    <form
                        className={cssStyles.form}
                        onSubmit={handleSubmit(data => handleClose(data))}
                        noValidate
                    >
                        <input
                            type={"hidden"}
                            name={"id"}
                            value={node.id}
                            {...register("id")}
                        />
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Name"
                            type="text"
                            fullWidth
                            variant="standard"
                            {...register("name")}
                            error={!!errors?.name}
                            helperText={errors?.name ? errors.name.message : null}
                        />
                       <MultilineTextField
                           id={"description"}
                           label={"Description"}
                           {...register("description")}
                           error={!!errors?.description}
                           helperText={errors?.description ? errors.description.message : null}
                           minRows={5}
                           maxRows={5} //to avoid crashes see: https://github.com/patrizz/kenchiku/issues/244
                           generator={theDescriptionGenerator}

                        />
                        <RichTextLabeler
                            id={"explanation"}
                            label={"Explanation"}
                            {...register("explanation")}
                            setValue={(value)=> {
                                LOGGER.debug("setting value: ", value)
                                setValue("explanation", value)
                                trigger("explanation")
                            }}
                            watch={watch}
                            error={!!errors?.explanation}
                            helperText={errors?.explanation ? errors.explanation.message : null}
                            nodesToRecognize={nodes?.filter(node=> {
                                return (
                                    node.type === NodeType.Actor.description
                                    || node.type === NodeType.Application.description
                                    || node.type === NodeType.Capability.description
                                )
                            })}
                        />
                        <BulletedTextField
                            id={"advantages"}
                            name={"advantages"}
                            label={"Advantages"}
                            register={register}
                            errors={errors}
                            generator={theAdvantagesGenerator}
                            bullet={"+"}
                            control={control}
                        />
                        <BulletedTextField
                            id={"disadvantages"}
                            name={"disadvantages"}
                            label={"Disadvantages"}
                            register={register}
                            errors={errors}
                            generator={theDisadvantagesGenerator}
                            bullet={"-"}
                            control={control}
                        />
                        <NodeSelector
                            label={PARENT_NODE_LABEL}
                            nodeType={PARENT_NODE_TYPE}
                            nodeTypeName={PARENT_NODE_TYPE_NAME}
                            nodeRootFolderId={PARENT_NODE_ROOT_ID}
                            multiSelect={false}
                            name={"parentId"}
                            control={control}
                            error={!!errors?.parentId}
                            helperText={errors?.parentId ? errors.parentId.message : null}
                        />
                        <NodeSelector
                            label={NEWAPPLICATIONS_NODE_LABEL}
                            nodeType={NEWAPPLICATIONS_NODE_TYPE}
                            nodeTypeName={NEWAPPLICATIONS_NODE_TYPE_NAME}
                            nodeRootFolderId={NEWAPPLICATIONS_NODE_ROOT_ID}
                            multiSelect={true}
                            name={"newApplicationIds"}
                            control={control}
                            error={!!errors?.newApplicationIds}
                            helperText={errors?.newApplicationIds ? errors.newApplicationIds.message : null}
                        />
                        <NodeSelector
                            label={UPDATEDAPPLICATIONS_NODE_LABEL}
                            nodeType={UPDATEDAPPLICATIONS_NODE_TYPE}
                            nodeTypeName={UPDATEDAPPLICATIONS_NODE_TYPE_NAME}
                            nodeRootFolderId={UPDATEDAPPLICATIONS_NODE_ROOT_ID}
                            multiSelect={true}
                            name={"updatedApplicationIds"}
                            control={control}
                            error={!!errors?.updatedApplicationIds}
                            helperText={errors?.updatedApplicationIds ? errors.updatedApplicationIds.message : null}
                        />
                        <NodeSelector
                            label={DELETEDAPPLICATIONS_NODE_LABEL}
                            nodeType={DELETEDAPPLICATIONS_NODE_TYPE}
                            nodeTypeName={DELETEDAPPLICATIONS_NODE_TYPE_NAME}
                            nodeRootFolderId={DELETEDAPPLICATIONS_NODE_ROOT_ID}
                            multiSelect={true}
                            name={"deletedApplicationIds"}
                            control={control}
                            error={!!errors?.deletedApplicationIds}
                            helperText={errors?.deletedApplicationIds ? errors.deletedApplicationIds.message : null}
                        />
                        <DialogActions className={cssStyles.actionsDiv}>
                            <Button onClick={()=>handleCancel()}>Cancel</Button>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={cssStyles.submit}
                            >Save</Button>
                        </DialogActions>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    );
}
