import * as jointjs from "@joint/plus";

export function createApplicationRectangle(blockWidth, blockHeight, x, y, width, height, label, isSelected=false, isSoftSelected=false) {
    return new jointjs.shapes.standard.Rectangle({
        position: {x: x, y: y},
        size: {width: width, height: height},
        attrs: {
            body: {
                fill: 'skyblue',
                opacity: 1,
                strokeWidth: 4,
                stroke: (isSelected ? '#FC5185' : (isSoftSelected ? '#FFD166' : 'skyblue')),
                rx: 5,
                ry: 5,
            },
            label: {
                text: label,
                fill: 'black',
                fontSize: 12,
                fontWeight: 'bold',
                textWrap: {
                    width: blockWidth - 10,
                    height: blockHeight - 10,
                    ellipsis: true,
                },
                //pointerEvents: 'none',
            },
        },
    })
}
