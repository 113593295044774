import cssStyles from "./AccountSubscriptionPage.module.css"
import React from "react";
import AccountMenuBar from "./AccountMenuBar";
import {SubscriptionTable} from "./SubscriptionTable";
import CreditsTable from "./CreditsTable";

export default function AccountSubscriptionsPage() {

    return <div className={cssStyles.mainDiv}>
        <AccountMenuBar pane={"subscriptionPane"}/>
        <SubscriptionTable/>
    </div>
}
