import * as joint from "@joint/plus";
import {addClasses} from "../../../utils/PresentationUtils";
import cssStyles from "./GridStackComponent4.module.css";
import {getCustomProp, setCustomProp, showDialog} from "./utils/JointjsUtils";
import {NodeTypeName} from "../../../model/Constants";
import Logger from "../../../utils/Logger";
import {resizeCapability} from "./GridStackComponent4_utils";
import {NODE_FILL_COLORS} from "../../../utils/NodeTypeColors";

const LOGGER = new Logger("GridStackComponent4_ShapeFactory.js", 1)

export function applicationTreeToRectangle(applicationTree) {
    const application = applicationTree?.application
    const contentWrapperClasses = addClasses([cssStyles.application])
    const content = `<div class="${contentWrapperClasses}"><span class="${cssStyles.gridContentSpan}">${application.name}</span></div>`
    const widget = {
        x: 0, y: 0,
        id: application.id,
        content: content,
        h: 1, w: 1,
    }
    setCustomProp(widget, "applicationId", application?.id)
    return widget
}

export function capabilityTreeToRectangle(paper, getNodeById, capabilityTree, x, y, childrensDirection, level) {
    if (!childrensDirection) {
        childrensDirection = "horizontal"
    }
    if (!level) {
        level = 0
    }
    const capability = capabilityTree?.capability
    const graph = paper.model
    const title = (capability?.name || "-")

    const headeredRectangle = new joint.shapes.standard.HeaderedRectangle();
    headeredRectangle.size(100, 70);
    headeredRectangle.position(x, y);
    headeredRectangle.attr('root/title', title);
    headeredRectangle.attr('header/fill', 'lightgray');
    headeredRectangle.attr('body/fill', (level%2===0?"orange":"#FFE97A"))
    headeredRectangle.attr('headerText', {
        text: title,
        textWrap:{
            width: -10, // reference width minus 10
            maxLineCount: 1, // half of the reference height
            ellipsis: true // could also be a custom string, e.g. '...!?'
        }});
    setCustomProp(headeredRectangle, "capabilityId", capability?.id)
    setCustomProp(headeredRectangle, "type", NodeTypeName.Capability)
    graph.addCell(headeredRectangle)
    let childX = x + 10
    let childY = y + 40
    capabilityTree?.childCapabilities?.forEach((childCapability, index)=>{
        const childCapabilityRectangle = capabilityTreeToRectangle(paper, getNodeById, childCapability, childX, childY, (childrensDirection==="horizontal"?"vertical":"horizontal"), level+1)
        headeredRectangle.embed(childCapabilityRectangle)
        graph.addCell(childCapabilityRectangle)
        if (childrensDirection==="horizontal") {
            childX = childX + childCapabilityRectangle.size().width + 10
        } else {
            childY = childY + childCapabilityRectangle.size().height + 10
        }
    })
    resizeCapability(graph, headeredRectangle)
    return headeredRectangle
}

export function createApplicationRectangle(paper, getNodeById, applicationTree, x, y, deleteApplicationHandler) {
    let rectangle = new joint.shapes.standard.Rectangle();
    rectangle.size(70, 50);
    rectangle.position(x, y);
    rectangle.attr('root/title', 'joint.shapes.standard.Rectangle');
    rectangle.attr('body/fill', 'lightblue');
    rectangle.attr('label', {
        text: (applicationTree?.application?.name || "-"),
        textWrap:{
            width: -10, // reference width minus 10
            height: -10, // half of the reference height
            ellipsis: true // could also be a custom string, e.g. '...!?'
    }});
    setCustomProp(rectangle, "type", NodeTypeName.Application)
    setCustomProp(rectangle, "applicationId", applicationTree?.application?.id)
    updateApplicationRectangle(paper, getNodeById, applicationTree?.application, rectangle)
    return rectangle
}

export function createCapabilityRectangle(paper, getNodeById, capabilityTree, x, y, deleteCapabilityHandler) {
    const graph = paper.model
    const rectangle =  capabilityTreeToRectangle(paper, getNodeById, capabilityTree, x, y, "horizontal", 0)
    //add the cell to the graph, so we can add tools to it
    graph.addCell(rectangle)
    setCustomProp(rectangle, "type", NodeTypeName.Capability)
    setCustomProp(rectangle, "capabilityId", capabilityTree?.capability?.id)
    updateCapabilityRectangle(paper, getNodeById, capabilityTree?.capability, rectangle)
    return rectangle
}

export function createTechnologyRectangle(paper, getNodeById, technologyTree, x, y, deleteTechnologyHandler) {
    let rectangle = new joint.shapes.standard.Rectangle();
    rectangle.size(70, 50);
    rectangle.position(x, y);
    rectangle.attr('root/title', 'joint.shapes.standard.Rectangle');
    rectangle.attr('body/fill', NODE_FILL_COLORS[NodeTypeName.Technology]);
    rectangle.attr('label', {
        text: (technologyTree?.technology?.name || "-"),
        textWrap:{
            width: -10, // reference width minus 10
            height: -10, // half of the reference height
            ellipsis: true // could also be a custom string, e.g. '...!?'
        }});
    setCustomProp(rectangle, "type", NodeTypeName.Technology)
    setCustomProp(rectangle, "technologyId", technologyTree?.technology?.id)
    updateTechnologyRectangle(paper, getNodeById, technologyTree?.technology, rectangle)
    return rectangle
}

export function updateApplicationRectangle(paper, getNodeById, applicationNode, cell) {
    if (!applicationNode?.id || !getNodeById(applicationNode?.id)) {
        cell.attr('body/fill', 'lightgray');
        cell.attr('stroke/fill', 'lightgray');
    }
    addToolsToRectangle(paper, getNodeById, cell, "Are you sure you want to remove this application from the diagram?")
}

function getCapabilityLevel(getNodeById, capabilityNode) {
    LOGGER.trace("getCapabilityLevel: capabilityNode.id:", capabilityNode?.id)
    let level = 0
    if (capabilityNode) {
        let parent = capabilityNode
        while (parent.parentId !== "_capabilities" && (parent = getNodeById(parent.parentId))) {
            level++
        }
    }
    return level
}

export function updateCapabilityRectangle(paper, getNodeById, capabilityNode, cell) {
    const level = getCapabilityLevel(getNodeById, capabilityNode)
    const color = (level%2===0?"orange":"#FFE97A")
    cell.attr('body/fill', color);
    addToolsToRectangle(paper, getNodeById, cell, "Are you sure you want to remove this capability from the diagram?")

    const childCells = cell.getEmbeddedCells()
    childCells?.forEach((childCell)=>{
        const childNodeType = getCustomProp(childCell, "type")
        if (childNodeType === NodeTypeName.Capability) {
            const childNode = getNodeById(getCustomProp(childCell, "capabilityId"))
            updateCapabilityRectangle(paper, getNodeById, childNode, childCell)
        }
    })
}

export function updateTechnologyRectangle(paper, getNodeById, technologyNode, cell) {
    if (!technologyNode?.id || !getNodeById(technologyNode?.id)) {
        cell.attr('body/fill', 'lightgray');
        cell.attr('stroke/fill', 'lightgray');
    }
    addToolsToRectangle(paper, getNodeById, cell, "Are you sure you want to remove this technology from the diagram?")
}


function removeCell(getNodeById, cell) {
    /*
     1. remove the link between the cell and its parent -if any-
     2. remove the cell from the diagram
     */
    //remove parent/child link
    //TODO

    //remove node from the diagram
    cell.remove()

}

function confirmRemoval(getNodeById, cell, confirmText) {

    //whatever the type of the cell, we can remove it if it's not embedded
    if (!cell.isEmbedded()) {
        //we don't mess with the hierachy, just remove the cell
        removeCell(getNodeById, cell)
        return
    }

    showDialog({
        text: confirmText,
        buttons: [{
            id: "yes",
            text: "Remove",
            handler: () => removeCell(getNodeById, cell) //only when the user confirms, delete
        }, {
            id: "no",
            text: "Cancel",
            handler: () => {},
            sameAsClose: true,
        }],
    });

}


export function addToolsToRectangle(paper, getNodeById, cell, deleteText) {
    const view = cell.findView(paper)
    if (view) {
        if (!view.hasTools()) {
            let removeButton = new joint.elementTools.Remove({
                action: () => {
                    confirmRemoval(getNodeById, cell, deleteText)
                },
            });
            let toolsView = new joint.dia.ToolsView({
                tools: [
                    removeButton
                ]
            });
            view.addTools(toolsView);
        } else {
            LOGGER.trace("addToolsToCapabilityRectangle: view already has tools")
        }
    } else {
        LOGGER.warn("addToolsToCapabilityRectangle: view not found. Did you add the cell to the graph?")
    }
}
