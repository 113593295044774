import Logger from "../../../utils/Logger";
import cssStyles from "./RecoverPasswordPage.module.css";
import {useEffect, useState} from "react";
import {CircularProgress} from "@mui/material";
import {useParams} from "react-router";
import {useEnvironmentInfo} from "../../EnvironmentProvider/EnvironmentInfoProvider";
import {useStatusMessage} from "../../StatusMessenger/StatusMessageProvider";
import {useSecurityContext} from "../../../security/SecurityContext";
import {useNavigate} from "react-router-dom";

const LOGGER = new Logger("ConfirmAccountPage")


export function ConfirmAccountPage() {
    LOGGER.debug("Enter - ConfirmAccountPage")

    const { token } = useParams()

    const [confirming, setConfirming] = useState(true)
    const [error, setError] = useState(false)
    const [confirmed, setConfirmed] = useState(false)

    const {environmentInfo} = useEnvironmentInfo()
    const {signinWithUser} = useSecurityContext()
    const setNewMessage = useStatusMessage()

    const navigate = useNavigate();

    try {

        useEffect(() => {

            if (confirmed) {
                LOGGER.debug("Account already confirmed")
                return
            }

            if (error) {
                LOGGER.debug("Error in account confirmation")
                return
            }

            if (!environmentInfo) {
                LOGGER.debug("environmentInfo is not available yet")
                return
            }
            LOGGER.debug("environmentInfo set, confirming account")
            fetch(
                `/api/confirm-account`,
                {
                    method: 'POST',
                    body: JSON.stringify({token: token}),
                    headers: {'Content-Type': 'application/json'}
                }
            ).then(response => {
                LOGGER.debug("response:", response)
                if (response.status === 200 || response.status === 204) {
                    LOGGER.debug("response status OK")
                    return response.json()
                } else {
                    LOGGER.debug("response status KO")
                    throw new Error(`Error: ${response.status}`)
                }
            }).then(userResponse => {
                LOGGER.debug("response user:", userResponse)
                const user = userResponse?.user
                LOGGER.debug("user:", user)
                if (user) {
                    LOGGER.debug("Got a user object, signing in")
                    signinWithUser(user)
                    setConfirming(false)
                    setConfirmed(true)
                    setError(false)
                    setNewMessage("Account confirmed. You are being signed in.", "info")
                    navigate("/set-password")
                } else {
                    LOGGER.debug("No user in the response")
                    setConfirming(false)
                    setConfirmed(false)
                    setError(true)
                    setNewMessage("Account confirmation failed. Please try again.", "alert")
                }
            }).catch(error => {
                LOGGER.error("Error while confirming account", error)
                setConfirming(false)
                setConfirmed(false)
                setError(true)
                setNewMessage("Account confirmation failed. Please try again.", "alert")
            })
            // eslint-disable-next-line
        }, [environmentInfo]);
    } catch (e) {
        LOGGER.error("Error in ConfirmAccountPage", e)
    }

    return <div className={cssStyles.mainDiv}>
        <div className={cssStyles.formDiv}>
            {confirming ?
                <>
                    <h1>Confirming account...</h1>
                    <CircularProgress/>
                </>
                :
                (error? <>
                    <h1>Account confirmation failed</h1>
                    <p>Please try again.</p>
                </> : <>
                    <h1>Account confirmed</h1>
                    <p>Your account has been confirmed. You can now sign in.</p>
                </>)
            }
        </div>
    </div>
}
