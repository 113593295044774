import * as jointjs from "@joint/plus";
import cssStyles from "./ApplicationViewDiagram2.module.css"
import React, {forwardRef, useEffect, useRef, useState} from "react";
import Logger from "../../../utils/Logger";
import {Checkbox, FormControl, FormControlLabel, IconButton} from "@mui/material";
import HighlightAltIcon from '@mui/icons-material/HighlightAlt';
import ApplicationComponentPaper from "./ApplicationViewDiagram2/ApplicationComponentPaper";
import {isEqual} from "lodash";
import {getCustomProp, showDialog} from "./utils/JointjsUtils";
import {addClasses} from "../../../utils/PresentationUtils";
import DataExchangeGrid from "./ApplicationViewDiagram2/DataExchangeGrid";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import 'react-tabs/style/react-tabs.css';
import ApplicationGrid from "./ApplicationViewDiagram2/ApplicationGrid";
import ShareButton from "./ShareButton/ShareButton";
import {download} from "./utils/DiagramUtils";
import RefreshIcon from '@mui/icons-material/Refresh';
import LayersClearIcon from '@mui/icons-material/LayersClear';
import {useModel} from "../../../model/ModelContext";
import {useDrop} from "react-dnd";
import {useTreeDndContext} from "./TreeDndContext";
import {ItemTypes} from "@minoru/react-dnd-treeview";
import {getApplicationViewAtPosition} from "./ApplicationViewDiagram2/ApplicationComponentPaperFunctions";
import {useStatusMessage} from "../../StatusMessenger/StatusMessageProvider";


const LOGGER = new Logger("ApplicationViewDiagram2", 1)

function viewShareOptions() {
    LOGGER.debug("getting viewShareOptions")
    return  [
        {
            format: "PNG",
            label: "Export as PNG image",
            grid: true,
            executeOption: async (paper)=>{
                jointjs.format.toPNG(paper, (dataURL) => {
                    download(dataURL, 'graph.png');
                }, {grid:true});
            }
        },
    ]
}

function ApplicationViewDiagram2({
                         node,
                         isPlaywrightMouseDownOnNode,
                     }, ref) {

    const {isDraggingActive, nodeBeingDragged} = useTreeDndContext()

    const {saveNode, getNodeById, updatedNode} = useModel()

    const diagramRef = useRef()
    const applicationsDropDivRef = useRef()
    const applicationComponentPaperRef = useRef()

    const labelShowGrid = { label:"Show grid" };
    const labelAddLinks = { label:"Auto-add data exchanges on App drop" };
    const labelSelection = { label:"Select modus" };

    const [automaticallyAddLinks, setAutomaticallyAddLinks] = useState(true)
    const [showMasterData, setShowMasterData] = useState(true)

    const [isSelectionActive, setIsSelectionActive] = useState(false)

    const [canAcceptDrop, setCanAcceptDrop] = useState(false)

    const setNewMessage = useStatusMessage()


    useEffect(() => {
        if (updatedNode?.id !== node?.id) {
            refreshPaper()
        } else {
            //don't refresh the paper, because that would lead to saving the node, and then the updatednode would change, and we'd end up in an infinite loop
        }


    }, [updatedNode]);

    useEffect(() => {
        if (isDraggingActive) {

            switch (nodeBeingDragged?.type) {
                case "application":
                case "data_object":
                    setCanAcceptDrop(true)
                    break
                default:
                    setCanAcceptDrop(false)
                    break
            }
        } else {
            setCanAcceptDrop(false)
        }
    }, [isDraggingActive, nodeBeingDragged]);

    const [{ isOver, isOverCurrent }, drop] = useDrop(
        () => ({
            accept: ItemTypes.TREE_ITEM, //['NODE', 'ApplicationComponent'],
            canDrop(_item, monitor) {
                const itemType = _item?.data?.type
                switch (itemType) {
                    case "application":
                    case "data_object":
                    case "NODE":
                        return true
                    default:
                        return false
                }
            },
            hover(_item, monitor) {
                applicationComponentPaperRef.current.setHoverClientOffset(monitor.getClientOffset())
            },
            drop(_item, monitor) {
                LOGGER.trace(`isOver=${isOver}, ìsOverCurrent=${isOverCurrent}`)
                LOGGER.debug("DROP! _item=", _item)
                //LOGGER.debug("DROP! monitor", monitor)
                const clientOffset = monitor.getClientOffset()
                LOGGER.debug("DROP! monitor.getClientOffset()", clientOffset)
                LOGGER.debug("DROP! monitor.didDrop()=", (monitor.didDrop()? "true":"false"))
                applicationComponentPaperRef.current.endHover()
                const didDrop = monitor.didDrop()
                if (didDrop) {
                    LOGGER.debug("didDrop already")
                    return _item
                }

                LOGGER.debug("checking if diagramRef is defined")
                if (diagramRef) {
                    const itemType = monitor?.getItemType()
                    if (itemType) {
                        LOGGER.debug(`itemType found:`, itemType)
                    } else {
                        LOGGER.debug("itemType not found")
                    }
                    switch (itemType) {
                        case ItemTypes.TREE_ITEM:
                            const objectType = _item?.data?.type
                            switch (objectType) {
                                case "application": {
                                    LOGGER.debug("drop.monitor.getClientOffset(): ", clientOffset)
                                    //add the node to the diagram...
                                    const node = getNodeById(_item.id)
                                    applicationComponentPaperRef.current.addNode(node, clientOffset.x, clientOffset.y)
                                    break
                                }
                                case "data_object": {
                                    LOGGER.debug("drop.monitor.getClientOffset(): ", clientOffset)
                                    //add the node to the application, if the data_object is dropped on an application
                                    const paperCoordinates = applicationComponentPaperRef.current.getPaper().clientToLocalPoint(clientOffset.x, clientOffset.y)
                                    const applicationView = getApplicationViewAtPosition(applicationComponentPaperRef.current.getGraph(), paperCoordinates.x, paperCoordinates.y)
                                    if (applicationView) {
                                        LOGGER.debug("data_object dropped on applicationView:", applicationView)
                                        const applicationId = getCustomProp(applicationView, "applicationId")
                                        handleAddDataObject(applicationId, _item.id)
                                    } else {
                                        LOGGER.debug("data_object dropped on no applicationView")
                                        setNewMessage("Data Object should be dropped onto an Application")
                                    }
                                }
                                case "technology": {
                                    LOGGER.debug("technology dropped")
                                    LOGGER.debug("drop.monitor.getClientOffset(): ", clientOffset)
                                    //add the node to the diagram...
                                    const paperCoordinates = applicationComponentPaperRef.current.getPaper().clientToLocalPoint(clientOffset.x, clientOffset.y)
                                    const applicationView = getApplicationViewAtPosition(applicationComponentPaperRef.current.getGraph(), paperCoordinates.x, paperCoordinates.y)
                                    if (applicationView) {
                                        LOGGER.debug("data_object dropped on applicationView:", applicationView)
                                        const applicationId = getCustomProp(applicationView, "applicationId")
                                        handleAddTechnology(applicationId, _item.id)
                                    } else {
                                        LOGGER.debug("data_object dropped on no applicationView")
                                        setNewMessage("Data Object should be dropped onto an Application")
                                    }
                                    break
                                }
                                default:
                                    break
                            }

                            break
                        default:
                            LOGGER.debug("unsupported item type '" + itemType + "' dropped: ", _item)
                            break
                    }

                } else {
                    LOGGER.debug("diagramRef undefined")
                }

                return _item
            },
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                isOverCurrent: monitor.isOver({ shallow: true }),
                clientOffset: monitor.getClientOffset() ,
            }),
        }),
        [] /* don't forget the dependecies! kudos to: https://stackoverflow.com/a/70757948*/,
    )

    //TODO
    function handleAddDataObject(nodeId, dataObjectId) {
        const application = getNodeById(nodeId)
        if (!application) {
            LOGGER.debug("application not found")
            //it was -probably- dropped on a deleted application.
            setNewMessage("Cannot modify deleted application")
            return
        }
        const dataObject = getNodeById(dataObjectId)
        if (!dataObject) {
            LOGGER.debug("dataObject not found")
            return
        }
        if (!application.masterDataObjectIds) {
            application.masterDataObjectIds = []
        }
        application.masterDataObjectIds.push(dataObjectId)
        LOGGER.trace("saving application:", application)
        saveNode(application)
        LOGGER.debug("refreshing paper")
        refreshPaper()
    }

    function handleAddTechnology(nodeId, technologyId) {
        const application = getNodeById(nodeId)
        if (!application) {
            LOGGER.debug("application not found")
            //it was -probably- dropped on a deleted application.
            setNewMessage("Cannot modify deleted application")
            return
        }
        const technology = getNodeById(technologyId)
        if (!technology) {
            LOGGER.debug("technology not found")
            return
        }
        if (!application.supportingTechnologyIds) {
            application.supportingTechnologyIds = []
        }
        application.supportingTechnologyIds.push(technologyId)
        LOGGER.trace("saving application:", application)
        saveNode(application)
        LOGGER.debug("refreshing paper")
        refreshPaper()
    }

    drop(applicationsDropDivRef)

    //TODO
    function handleOnDiagramClick(event) {
        //onSelectNodeCallback(view.id)
    }


    //TODO
    /*
    function onSelectionRectangleDrawn(rectangle) {

    }
     */

    function refreshPaper() {
        applicationComponentPaperRef.current.refreshPaper()
    }

    function clearPaper() {
        showDialog({
            text: "Are you sure you wish to clear all elements from the diagram?",
            buttons: [{
                id: "yes",
                text: "YES",
                handler: () => {
                    applicationComponentPaperRef.current.clearPaper()
                    //todo update the node's graph et al. attributes & save it
                    node.view = applicationComponentPaperRef.current.toJSON()
                    saveNode(node)
                },
            }, {
                id: "no",
                text: "NO",
                handler: () => {},
                sameAsClose: true,
            } ],
        });

    }

    const [isGridOpen, setIsGridOpen] = useState(true);

    const [nodeIdsOnTheGraph, setNodeIdsOnTheGraph] = useState([])

    const [selectedDataExchangeIds, setSelectedDataExchangeIds] = useState([])

    return (<div ref={ref} className={cssStyles.main}>
        {node?.name}
        <hr/>
        <div className={cssStyles.filterPane}>
            <FormControl component="fieldset">
                <FormControlLabel
                    {...labelShowGrid}
                    control={<Checkbox defaultChecked />}
                    value={isGridOpen}
                    onChange={(event)=>{setIsGridOpen(oldValue=>!oldValue)}}
                />
            </FormControl>
            <FormControl component="fieldset">
                <FormControlLabel
                    {...labelAddLinks}
                    control={<Checkbox defaultChecked />}
                    value={automaticallyAddLinks}
                    onChange={(event)=>{setAutomaticallyAddLinks(oldValue=>!oldValue)}}
                />
            </FormControl>
            <FormControl component="fieldset">
                <FormControlLabel
                    {...labelSelection}
                    control={<Checkbox
                        inputProps={{'aria-label': 'Checkbox demo'}}
                        icon={<HighlightAltIcon />}
                        checkedIcon={<HighlightAltIcon />}
                    />}
                    checked={isSelectionActive}
                    onChange={(event)=>{
                        LOGGER.debug("event:", event)
                        setIsSelectionActive(oldValue=>!oldValue)
                    }}
                />
            </FormControl>
            <FormControl component="fieldset">
                <ShareButton
                    getPaper={()=> {
                        return applicationComponentPaperRef.current.getPaper()
                    }}
                    shareOptions={viewShareOptions()}
                />
            </FormControl>
            <IconButton className={cssStyles.iconButton} aria-label="refresh" onClick={()=>{refreshPaper(node.graph)}}>
                <RefreshIcon className={cssStyles.icon}/>
            </IconButton>
            <IconButton className={cssStyles.redIconButton} aria-label="clear" onClick={()=>{clearPaper(node.graph)}}>
                <LayersClearIcon className={cssStyles.redIcon}/>
            </IconButton>
        </div>
        <div
            className={addClasses([cssStyles.applicationsDiv, (isGridOpen?cssStyles.gridShowing:"")])}
            ref={diagramRef}
            onClick={handleOnDiagramClick}
            styles={{backgroundColor: (isOver? "lightgreen":"white")}}
        >
            <div className={cssStyles.diagramWrapperDiv}>
                <div
                    data-testid={"dragarea-applicationview-paper"}
                    ref={applicationsDropDivRef}
                    className={((canAcceptDrop || isPlaywrightMouseDownOnNode)?cssStyles.dropZoneActive:cssStyles.dropZoneInactive)}
                ></div>
                <ApplicationComponentPaper
                    ref={applicationComponentPaperRef}
                    automaticallyAddLinks={automaticallyAddLinks}
                    showMasterData={showMasterData}
                    node={node}
                    selectedDataExchangeIds={selectedDataExchangeIds}
                    updateDataExchangeNodeIdsOnPaper={(dataExchangeIdsOnPaper)=>{
                        LOGGER.debug("ApplicationComponentPaper.updateDataExchangeNodeIdsOnPaper:", dataExchangeIdsOnPaper)
                        //TODO Make sure the exchanges are on the graph, and if they're not, add them
                        setSelectedDataExchangeIds(dataExchangeIdsOnPaper)
                    }}
                    onSaveGraphJSON={(graphJSON)=>{
                        LOGGER.trace("onSaveGraphJSON.graphJSON:", graphJSON)
                        //TODO !!! this fucks up existing views' .graph attributes when switching rapidly between view
                        if (!isEqual(graphJSON, node.graph)) {
                            node.graph = graphJSON
                            saveNode(node)
                        } else {
                            LOGGER.debug("graphJSON not changed")
                        }
                    }}
                    updateApplicationNodeIdsOnPaper={(nodeIds)=>{
                        setNodeIdsOnTheGraph(nodeIds)
                    }}
                    onEditNode={(dataExchangeId)=>{
                        LOGGER.debug(`editor opening ${dataExchangeId}`)
                    }}
                />
            </div>
            <div className={cssStyles.gridDiv}>

                <Tabs className={cssStyles.tabContainer}>
                    <TabList>
                        <Tab>Applications</Tab>
                        <Tab>Data Exchanges</Tab>
                    </TabList>
                    <TabPanel className={cssStyles.gridTabPanel}>
                        <ApplicationGrid
                            applicationNodeIds={nodeIdsOnTheGraph}
                        />
                    </TabPanel>
                    <TabPanel className={cssStyles.gridTabPanel}>
                        <DataExchangeGrid
                            nodeIds={selectedDataExchangeIds}
                            updateSelectedDataExchangeIds={(selectedDEIs)=>{
                                LOGGER.debug("selectedDFIs:", selectedDEIs)
                                //TODO Make sure the exchanges are on the graph, and if they're not, add them
                                setSelectedDataExchangeIds(selectedDEIs)
                            }}
                        />
                    </TabPanel>

                </Tabs>


            </div>
        </div>

    </div>)
}
export default forwardRef(ApplicationViewDiagram2)
