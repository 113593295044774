import {addAuthorizationHeader} from "../../../../utils/Api";
import Logger from "../../../../utils/Logger";

const LOGGER = new Logger("ScenarioGenerators")

export let descriptionGenerator = (node, user) => {
    return fetch("/api/generate-description", {
        method: "POST",
        headers: addAuthorizationHeader({}, null, user),
        body: JSON.stringify({
            nodeType: node.type,
            nodeName: node.name
        })
    }).then((response) => {
        return response.json();
    }).then((data) => {
        LOGGER.debug("got response from generator: ", data);
        return {success: true, data: data?.description};
    }).catch((err) => {
        LOGGER.error("Error: ", err);
        return {success: false, message: err};
    })
}
