import React, {forwardRef, useEffect, useRef, useState} from "react";
import cssStyles from "./TalDiagram3.module.css";
import Logger from "../../../utils/Logger";

import GridStackComponent4 from "./GridStackComponent4";
import {useDrop} from "react-dnd";
import {NodeType} from "../../../model/Constants";
import {TalDiagramToolbar} from "./TalDiagram/TalDiagramToolbar";
import {talShareOptions} from "./TalDiagram/talShareOptions";
import {useEnvironmentInfo} from "../../EnvironmentProvider/EnvironmentInfoProvider";
import {useSelectedNodes} from "../../SelectedNodes/SelectedNodesProvider";
import {useModel} from "../../../model/ModelContext";
import {useTreeDndContext} from "./TreeDndContext";
import {ItemTypes} from "@minoru/react-dnd-treeview";

const LOGGER = new Logger("TalDiagram3")

//const SAVE_FREQUENCY = 1000

function TalDiagram3({
                        isPlaywrightMouseDownOnNode,
                        setNodeBeingDragged
                    }, ref) {

    const {isDraggingActive, nodeBeingDragged} = useTreeDndContext()

    const {saveNode, getNodeById} = useModel()

    const [snapToGrid, setSnapToGrid] = useState(true)

    const diagramRef = useRef()
    const gridStackRef = useRef()

    const {selectedNodes, setSelectedNodes} = useSelectedNodes()

    const [node, setNode] = useState({})

    useEffect(() => {
        LOGGER.debug("TalDiagram3.useEffect.selectedNodes changed:", selectedNodes)
        if (!selectedNodes || selectedNodes.length === 0) {
            LOGGER.debug("no selected nodes")
            return
        }
        LOGGER.debug("selectedNodes[0]:", selectedNodes[0])
        setNode(selectedNodes[0])
    }, [selectedNodes]);

    const onAddChildNodeCallback = (nodeId, childNodeId)=>{
        const node = getNodeById(nodeId)
        const childNode = getNodeById(childNodeId)
        LOGGER.debug("onAddChildNodeCallback.node:", node)
        LOGGER.debug("onAddChildNodeCallback.childNode:", childNode)
        if (node.type === NodeType.Capability.description && childNode.type === NodeType.Application.description) {
            if (!childNode.supportedCapabilityIds) {
                childNode.supportedCapabilityIds = []
            }
            childNode.supportedCapabilityIds.push(node.id)
            //save the changed node
            saveNode(childNode)
            //now save the view itself
            saveNode(node)
            return true
        }
        return false
    }

    const [{ isOver, isOverCurrent }, dropRef] = useDrop(
        () => ({
            accept: ItemTypes.TREE_ITEM,
            canDrop(_item, monitor) {
                const itemType = _item?.data?.type
                switch (itemType) {
                    case "technology":
                    case "application":
                    case "capability":
                    case "NODE":
                        return true
                    default:
                        return false
                }
            },
            drop(_item, monitor) {
                LOGGER.trace(`isOver=${isOver}, ìsOverCurrent=${isOverCurrent}`)
                LOGGER.debug("DROP! _item=", _item)
                const didDrop = monitor.didDrop()
                if (didDrop) {
                    return _item
                }
                LOGGER.debug("has dropped == true")
                if (diagramRef) {
                    LOGGER.debug("drop.monitor.getClientOffset(): ", monitor.getClientOffset())
                    const node = getNodeById(_item.id)
                    //add the node to the diagram...
                    gridStackRef.current.addNode(node, monitor.getClientOffset().x, monitor.getClientOffset().y)
                    //setTileModels(currentTileModels=>currentTileModels.push(ctm))
                } else {
                    LOGGER.debug("diagramRef undefined")
                }
                return _item
            },
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                isOverCurrent: monitor.isOver({ shallow: true }),
            }),
        }),
        [],
    )

    const {environmentInfo} = useEnvironmentInfo()

    return <div key={"upper-pane-content"} id={"upper-pane-content"} className={cssStyles.upperControlPane}>
        <div className={cssStyles.diagramTitleDiv}>Diagram name: <span className={cssStyles.diagramNameSpan}>{node?.name}</span></div>
        <TalDiagramToolbar
            getPaper={()=>gridStackRef?.current?.getPaper()}
            snapToGrid={snapToGrid}
            onSnapToGridValueChange={(shouldSnapToGrid)=>setSnapToGrid(shouldSnapToGrid)}
            shareOptions={talShareOptions(gridStackRef?.current?.getPaper(), environmentInfo)}
            clearPaper={()=>{gridStackRef?.current?.clearPaper()}}
        />
        <div className={cssStyles.diagramDiv} ref={diagramRef}>
            <div
                data-testid={"tal-dropRef-div"}
                ref={dropRef}
                className={(isDraggingActive || isPlaywrightMouseDownOnNode?cssStyles.dropZoneActive:cssStyles.dropZoneInactive)}
            ></div>
            <GridStackComponent4
                ref={gridStackRef}
                node={node}
                snapToGrid={snapToGrid}
                gridSize={5}
                nodeBeingDragged={nodeBeingDragged}
                setNodeBeingDragged={setNodeBeingDragged}
                addChildNode={onAddChildNodeCallback}
                tileSelected={(tile)=>{setSelectedNodes((tile?tile.id:node.id))}}
                shouldIncludeApplications={true}
            />
        </div>
        <div className={cssStyles.bumper}></div>
    </div>

}

export default forwardRef(TalDiagram3)
