import cssStyles from "./AccountCreditsPage.module.css"
import React from "react";
import AccountMenuBar from "./AccountMenuBar";
import {SubscriptionTable} from "./SubscriptionTable";
import CreditsTable from "./CreditsTable";

export default function AccountCreditsPage() {

    //add a credits table
    //see:
    // * https://developer.paddle.com/build/subscriptions/bill-add-one-time-charge
    // * https://developer.paddle.com/api-reference/subscriptions/create-one-time-charge

    const credits = [
        {id:1, amount:"0.5", reason:"AI1", date: "12/12/2023"},
        {id:1, amount:"0.6", reason:"AI2", date: "13/12/2023"},
        {id:1, amount:"0.4", reason:"AI3", date: "14/12/2023"},
        {id:1, amount:"0.3", reason:"AI4", date: "15/12/2023"},
    ]

    return <div className={cssStyles.mainDiv}>
        <AccountMenuBar pane={"creditsPane"}/>
        <CreditsTable credits={credits}/>
    </div>
}
