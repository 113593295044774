export const NODE_FILL_COLORS = {
    "actor": "#dbb306",
    "_actors": "#dbb306",
    "actor_activity": "#CCF7FF",
    "_actor_activities": "#CCF7FF",
    "application": "#87CEEB",
    "_applications": "#87CEEB",
    "application_usage": "#87CEEB",
    "_applications_usages": "#87CEEB",
    "architecture_building_block": "#87CEEB",
    "_architecture_building_blocks": "#87CEEB",
    "business_process": "#ea796a",
    "_business_processes": "#ea796a",
    "capability": "#FFA500",
    "_capabilities": "#FFA500",
    "data_exchange": "#9fc9f5",
    "_data_exchanges": "#9fc9f5",
    "data_flow": "#9fc9f5",
    "_data_flows": "#9fc9f5",
    "data_object": "#9fc9f5",
    "_data_objects": "#9fc9f5",
    "design_decision": "#1AA89C",
    "_design_decisions": "#1AA89C",
    "domain": "#AFEC6E",
    "_domains": "#AFEC6E",
    "folder": "#000000",
    "_folders": "#000000",
    "functionality": "#CCF7FF",
    "_functionalities": "#CCF7FF",
    "_mega_root_id": "#FF0",
    "middleware": "#8cc54f",
    "_middlewares": "#8cc54f",
    "principle": "#ffd500",
    "_principles": "#ffd500",
    "reference_architecture": "#da321c",
    "_reference_architectures": "#da321c",
    "scenario": "#a41cda",
    "_scenarios": "#a41cda",
    "snapshots": "#b7b0b0",
    "_snapshots": "#b7b0b0",
    "technology": "#8cc54f",
    "_technologies": "#8cc54f",

}

export const NODE_TEXT_COLORS = {
    "actor": "#dddddd",
    "_actors": "#ffffff",
    "actor_activity": "#06509f",
    "_actor_activities": "#06509f",
    "application": "#000000",
    "_applications": "#000000",
    "application_usage": "#000000",
    "_applications_usages": "#000000",
    "architecture_building_block": "#000000",
    "_architecture_building_blocks": "#000000",
    "business_process": "#ffffff",
    "_business_processes": "#ffffff",
    "capability": "#ffffff",
    "_capabilities": "#ffffff",
    "data_object": "#000000",
    "_data_objects": "#000000",
    "data_flow": "#000000",
    "_data_flows": "#000000",
    "design_decision": "#ffffff",
    "_design_decisions": "#ffffff",
    "domain": "#000000",
    "_domains": "#000000",
    "folder": "#ffffff",
    "_folders": "#ffffff",
    "functionality": "#000000",
    "_functionalities": "#000000",
    "_mega_root_id": "#000000",
    "middleware": "#000000",
    "_middlewares": "#000000",
    "principle": "#000000",
    "_principles": "#000000",
    "reference_architecture": "#ffffff",
    "_reference_architectures": "#ffffff",
    "scenario": "#ffffff",
    "_scenarios": "#ffffff",
    "snapshots": "#000000",
    "_snapshots": "#000000",
}
