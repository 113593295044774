import cssStyles from "./TreeNavigator2.module.css"

import "./TreeIconStyles.css"
import React, {useEffect, useRef, useState} from "react"
import Logger from "../../utils/Logger"
import PropertyPane from "./PropertyPane"
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle
} from "@mui/material"
import {SplitterButtons} from "./SplitterButtons"
import FileUploader from "../FileUpload/FileUploader"
import {uploadFile} from "./UploadUtils"
import {addAuthorizationHeader} from "../../utils/Api"
import {
    getRootFolderLabel,
    NodeFolderRootIds
} from "../../model/Constants"
import ImprovementsPane from "./ImprovementsPane"
import DiagramPane from "./diagrams/DiagramPane"
import dateFormat from "dateformat"
import {useStatusMessage} from "../StatusMessenger/StatusMessageProvider";
import Split from '@uiw/react-split';
import {useSelectedNodes} from "../SelectedNodes/SelectedNodesProvider";
import {useLoadingOverlay} from "../LoadingOverlay/LoadingOverlay";
import {useWorkspaceContext} from "../../security/WorkspaceContext";
import {useSecurityContext} from "../../security/SecurityContext";
import {useModel} from "../../model/ModelContext";
import {ModelStatusBox} from "./ModelStatusBox";
import TreeComponent2 from "./TreeComponent2/TreeComponent2";

const LOGGER = new Logger("TreeNavigator2")

const LEFT_PANE_WIDTH=18
const RIGHT_PANE_WIDTH=20
const CENTER_PANE_WIDTH=100-LEFT_PANE_WIDTH-RIGHT_PANE_WIDTH

export default function TreeNavigator2() {

    LOGGER.debug("TreeNavigator2 entered")

    const setNewMessage = useStatusMessage()

    const {user, initializing} = useSecurityContext()
    const {selectedNodes} = useSelectedNodes()

    const {selectedWorkspace} = useWorkspaceContext()
    const {
        nodes, reload,
        loading, removing, updating,
        error
    } = useModel()

    const diagramPaneRef = useRef(null)

    const [leftPaneSize, setLeftPaneSize] = useState(LEFT_PANE_WIDTH + "%")
    const [centerPaneSize, setCenterPaneSize] = useState(CENTER_PANE_WIDTH + "%")
    const [rightPaneSize, setRightPaneSize] = useState(RIGHT_PANE_WIDTH + "%")
    const [fullscreen, setFullscreen] = useState(false)

    const setStatusMessage = useStatusMessage()

    const [uploadMessage, setUploadMessage] = useState("")

    const [showUploadForCategory, setShowUploadForCategory] = useState("")

    const [improvements, setImprovements] = useState([])

    const [areImprovementsLoading, setAreImprovementsLoading] = useState(false)

    //kudos to: https://stackoverflow.com/a/66913595
    const [shiftHeld, setShiftHeld] = useState(false);

    const [workspaceId, setWorkspaceId] = useState("")

    useEffect(()=>{
        if (!error) {
            return
        }
        setStatusMessage(error)
        // eslint-disable-next-line
    }, [error])

    useEffect(() => {
        if (initializing) {
            LOGGER.debug("initializing...")
            loadingOverlay.show("Logging on...")
        } else {
            LOGGER.debug("done initializing...")
            loadingOverlay.hide()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initializing]);

    useEffect(() => {
        if (loading) {
            LOGGER.debug("loading model...")
            loadingOverlay.show("Loading model...")
        } else {
            LOGGER.debug("done loading model...")
            loadingOverlay.hide()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    useEffect(() => {
        if (removing) {
            LOGGER.debug("Removing node...")
            loadingOverlay.show("Removing node...")
        } else {
            LOGGER.debug("done removing node...")
            loadingOverlay.hide()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [removing]);

    useEffect(() => {
        if (updating && updating.source === "TreeNavigator2") {
            LOGGER.debug("updating node...")
            loadingOverlay.show("Updating node...")
        } else {
            LOGGER.debug("done updating node...")
            loadingOverlay.hide()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updating]);

    useEffect(()=>{
        if (selectedWorkspace) {
            LOGGER.debug("selectedWorkspace:", selectedWorkspace)
            setWorkspaceId(selectedWorkspace)
        } else {
            LOGGER.debug("selectedWorkspace is empty")
        }
    }, [selectedWorkspace])

    function downHandler({key}) {
        if (key === 'Shift') {
            setShiftHeld(true);
        }
    }

    function upHandler({key}) {
        if (key === 'Shift') {
            setShiftHeld(false);
        }
    }

    useEffect(() => {
        window.addEventListener('keydown', downHandler);
        window.addEventListener('keyup', upHandler);
        return () => {
            window.removeEventListener('keydown', downHandler);
            window.removeEventListener('keyup', upHandler);
        };
    }, []);

    useEffect(() => {
        LOGGER.debug("shiftHeld:", shiftHeld)
    }, [shiftHeld]);

    const propertyPaneRef = useRef()

    const loadingOverlay = useLoadingOverlay()

    useEffect(()=>{
        if (areImprovementsLoading) {
            LOGGER.debug("areImprovementsLoading is true")
            return
        }
        if (selectedNodes?.length < 1) {
            LOGGER.debug("selectedNodes is empty")
            return
        }
        setStatusMessage("Loading improvements...")
        setAreImprovementsLoading(true)
        fetch("/api/report-improvements?nid="+selectedNodes[0].id + "&type=" + selectedNodes[0].type, workspaceId && user && {
            // only attach headers if user is logged in, but still make the request regardless
            headers: addAuthorizationHeader({}, workspaceId, user)
        })
            .then(res =>res.json())
            .then(data=>{
                LOGGER.debug("improvement data:", data)
                setImprovements(data.improvements)
            })
            .catch((e)=>{
                LOGGER.error("Error fetching improvements: ", e)
            })
            .finally(()=>{
                setAreImprovementsLoading(false)
            })
        //disabling the next line because I can't add setStatusMessage to the dependency list, otherwise it will be called in an infinite loop
        // eslint-disable-next-line
    }, [selectedNodes, workspaceId, user])

    function handleFileDialogClose() {

    }

    function createSnapshot() {
        setNewMessage("Creating a snapshot")

        const now = new Date();
        const name = "Snapshot-" + dateFormat(now, "dddd, mmmm dS, yyyy, h:MM:ss TT")

        const snapShotInstance = {}
        snapShotInstance.name = name
        snapShotInstance.description = "This is a manually created snapshot using kenchiku.ninja"
        snapShotInstance.createDate = now

        //remove the treenode specific attribtues, before saving the nodes to the snapshot
        snapShotInstance.snapshot = nodes.map(node=>{
            const clone = {...node}
            if (!clone?.parentId) {
                const folderId = NodeFolderRootIds[clone.type]?.description
                if (folderId) {
                    clone.parentId = folderId
                }
            }
            return clone
        })

        fetch("/api/manage-snapshots", workspaceId && user && {
            // only attach headers if user is logged in, but still make the request regardless
            headers: addAuthorizationHeader({}, workspaceId, user),
            method: "POST",
            body: {}
        })
            .then(res =>res.json())
            .then(async data => {
                console.debug("snapshot data:", data)
                setImprovements(data.improvements)
                reload()
            })
            .catch((e)=>{
                console.error("Error creating snapshot: ", e)
            })
    }

    useEffect(()=>{
        LOGGER.debug("selectedNodes:", selectedNodes.map((n)=>{return ({id:n.id, nodeId:n.nodeId, name:n.name})}))
    }, [selectedNodes])

    return <div className={[cssStyles.treeNavigator2, (fullscreen?cssStyles.fullscreen:"")].join(" ")}>

        {showUploadForCategory !== "" &&
            <Dialog open={showUploadForCategory !== ""} onClose={handleFileDialogClose}>
                <DialogTitle>Import - {getRootFolderLabel(showUploadForCategory)}</DialogTitle>
                <DialogContent>
                    <FileUploader
                        contentText={"Select your file to import " + getRootFolderLabel(showUploadForCategory)}
                        onDrop={async (files) => {
                            LOGGER.debug("onDrop.files:", files)
                            setUploadMessage("")
                            if (files && files[0]) {
                                // at least one file has been dropped so do something
                                LOGGER.debug("onDrop.files[0]:", files[0])
                                setNewMessage("Uploading file...")
                                try {
                                    await uploadFile(
                                        workspaceId,
                                        user,
                                        "csv",
                                        showUploadForCategory,
                                        files[0],
                                        async (result) => {
                                            setShowUploadForCategory("")
                                            await reload()
                                            setNewMessage("Successfully uploaded " + result + " row(s)")
                                        },
                                        (error) => {
                                            setShowUploadForCategory("")
                                            setNewMessage("ERROR: " + error, "alert")
                                        },
                                        (message) => {
                                            setNewMessage(message)
                                        })
                                } catch (e) {
                                    setShowUploadForCategory("")
                                    setNewMessage("Error happened during file upload", alert)
                                    LOGGER.error("onDrop.readFileAsTextString failed: ", e)
                                } finally {

                                }
                            }
                        }}
                        onCancel={()=>{setShowUploadForCategory("")}}
                        handleError={(msg, e) => {
                            LOGGER.error(msg, e)
                        }}
                        uploadMessage={uploadMessage}
                    />
                </DialogContent>
            </Dialog>
        }
        <div className={cssStyles.navigatorPane}>
            <div className={cssStyles.mainBar}>
                <SplitterButtons
                    buttonClicked={(button)=>{
                        switch (button) {
                            case 1: //left + center + right
                                setLeftPaneSize(LEFT_PANE_WIDTH + "%")
                                setCenterPaneSize(CENTER_PANE_WIDTH + "%")
                                setRightPaneSize(RIGHT_PANE_WIDTH + "%")
                                break
                            case 2: //Left + center
                                setLeftPaneSize(LEFT_PANE_WIDTH + "%")
                                setCenterPaneSize((100-LEFT_PANE_WIDTH) + "%")
                                setRightPaneSize("0%")
                                break
                            case 3: //center + right
                                setLeftPaneSize("0%")
                                setCenterPaneSize((100-RIGHT_PANE_WIDTH) + "%")
                                setRightPaneSize(RIGHT_PANE_WIDTH + "%")
                                break
                            case 4: //center only
                                setLeftPaneSize("0%")
                                setCenterPaneSize("100%")
                                setRightPaneSize("0%")
                                break
                            case 5: //fullscreen
                                setFullscreen((fs)=>(fs=!fullscreen))
                                break
                            default: //unknown
                                break
                        }
                    }}/>
                <ModelStatusBox/>
            </div>
            <div className={cssStyles.contextualMenuBar}>
                <Button
                    className={cssStyles.actionButton}
                    variant={"contained"}
                    type={"button"}
                    onClick={() => {
                    createSnapshot()
                }}>Create Snapshot</Button>
            </div>

            <div className={cssStyles.splitterBar}>
                <Split style={{ height: "100%", width: "calc( 100% - 2px)", border: '1px solid #d5d5d5', borderRadius: 3, overflow: 'hidden' }}>
                    <div id={"left-pane-content"} style={{width: leftPaneSize}} className={cssStyles.leftControlPane}>
                        <div id='tree-navigator' className={cssStyles.treeViewPane}>
                            <TreeComponent2/>
                        </div>
                    </div>
                    <div id={"center-pane-content"} style={{width: centerPaneSize}} className={cssStyles.centerControlPane}>
                        <DiagramPane
                            ref={diagramPaneRef}
                        />
                    </div>
                    <div id={"right-pane-content"} style={{width: rightPaneSize}} className={cssStyles.rightControlPane}>
                        <div className={cssStyles.propertiesPaneWrapper}>
                            <div className={cssStyles.propertiesPane}>
                                <h2>Properties</h2>
                                <hr/>
                                <PropertyPane
                                    ref={propertyPaneRef}
                                />
                                {improvements && improvements.length>0 &&
                                    <>
                                        <h2>Improvements</h2>
                                        <hr/>
                                        {areImprovementsLoading && <span>Checking for improvements...</span>}
                                        {!areImprovementsLoading && <ImprovementsPane showLinkToNode={false} improvementsArray={improvements}/>}
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </Split>
            </div>
        </div>
    </div>
}
