//https://rogulski.it/blog/react-material-checkbox-form-validation/
//https://www.youtube.com/watch?v=10Z0OCPfubI

import cssStyles from "./TechnologyEditor.module.css"

import {useEffect} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from "@mui/material";
import NodeSelector from "./selectors/NodeSelector";

import {useForm} from "react-hook-form"
import {object, string, date, array, mixed, number} from 'yup';
import {useYupValidationResolver} from "./EditorUtils"
import Logger from "../../../utils/Logger"
import {
    Availability,
    Confidentiality,
    DEFAULT_LifeCycleStageValues,
    DEFAULT_RPO,
    DEFAULT_RTO,
    DEFAULT_StrategicClassificationValues,
    DEFAULT_SupportedRateOfChangeValues,
    Integrity,
    LifeCycleStageValues,
    NodeFolderRootIds,
    NodeType,
    RPOValues,
    RTOValues
} from "../../../model/Constants";
import MultilineTextField from "./fields/MultilineTextField";
import StrategicClassificationSelector from "./selectors/StrategicClassificationSelector";
import ConfidentialitySelector from "./selectors/ConfidentialitySelector";
import IntegritySelector from "./selectors/IntegritySelector";
import AvailabilitySelector from "./selectors/AvailabilitySelector";
import RPOSelector from "./selectors/RPOSelector";
import RTOSelector from "./selectors/RTOSelector";
import SupportedRateOfChangeSelector from "./selectors/SupportedRateOfChangeSelector";
import LifeCycleStageSelector from "./selectors/LifeCycleStageSelector";
import {useSecurityContext} from "../../../security/SecurityContext";
import {useSubscriptionContext} from "../../../subscription/SubscriptionContext";
import {descriptionGenerator} from "./generators/GenericGenerators";

const LOGGER = new Logger("TechnologyEditor")

const TECHNOLOGY_OBJECT_SCHEMA = object().shape({
    //id: string().required(),
    name: string().required(),
    description: string().required().min(10).max(5000),
    parentId: string().required().default(NodeFolderRootIds.TechnologyRootId.description),
    createdOn: date().default(() => new Date()),
    updatedOn: date().default(() => new Date()),
    supportedCapabilityIds: array().of(string()).default([]),
    confidentiality: mixed().oneOf(Confidentiality).default("1"),
    integrity: mixed().oneOf(Integrity).default("1"),
    availability: mixed().oneOf(Availability).default("1"),
    rpo: mixed()
        .oneOf(RPOValues).default(DEFAULT_RPO),
    rto: mixed()
        .oneOf(RTOValues).default(DEFAULT_RTO),
    lifeCycleStage: string().oneOf(LifeCycleStageValues.map(v=>v.name)).default(DEFAULT_LifeCycleStageValues),
    introductionCost: number().min(0),
    yearlyCost: number().min(0),
});

const PARENT_NODE_LABEL = "Parent Technology Object"
const PARENT_NODE_ROOT_ID = NodeFolderRootIds.TechnologyRootId.description
const PARENT_NODE_TYPE = NodeType.Technology.description
const PARENT_NODE_TYPE_NAME = "Technology"


async function createObject(
    id, name, description, parentId,
    confidentiality, integrity, availability,
    rpo, rto,
    lifeCycleStage,
    introductionCost, yearlyCost,
    supportedCapabilityIds,
    supportedRateOfChange,
    strategicClassification,
) {
    LOGGER.trace("createObject")
    let validationObject = {
        id,
        name,
        description,
        parentId,
        confidentiality,
        integrity,
        availability,
        rpo,
        rto,
        lifeCycleStage,
        introductionCost, yearlyCost,
        supportedCapabilityIds,
        supportedRateOfChange,
        strategicClassification,
        type: PARENT_NODE_TYPE
    }
    LOGGER.trace("validationObject: ", validationObject)
    const node = await TECHNOLOGY_OBJECT_SCHEMA.validate(validationObject)
    LOGGER.trace("done validating, returning node:", node)
    return node
}

export default function TechnologyEditor({
                              isOpen,
                              titleOfForm,
                              descriptionOfForm,
                              node,
                              onClose,
                              onCancel
                          }) {

    const handleClose = async (data, reason) => {
        LOGGER.debug("data:", data)
        LOGGER.debug("reason:", reason)
        if (reason && ["escapeKeyDown", "backdropClick"].includes(reason)) {
            handleCancel()
            return
        }
        onClose(await createObject(
            data.id,
            data.name,
            data.description,
            data.parentId,
            data.confidentiality,
            data.integrity,
            data.availability,
            data.rpo,
            data.rto,
            data.lifeCycleStage,
            data.introductionCost,
            data.yearlyCost,
            data.supportedCapabilityIds,
            data.supportedRateOfChange,
            data.strategicClassification
        ))
    };
    const handleCancel = () => {
        onCancel(node.id)
    };

    const defaultValues = {
        id:node?.id,
        name: "[name]",
        description: "[description]",
        parentId: node?.parentId,
        confidentiality: "1",
        integrity: "1",
        availability: "1",
        rpo: DEFAULT_RPO,
        rto: DEFAULT_RTO,
        lifeCycleStage: DEFAULT_LifeCycleStageValues,
        introductionCost: 0,
        yearlyCost: 0,
        supportedCapabilityIds: [],
        supportedRateOfChange: DEFAULT_SupportedRateOfChangeValues,
        strategicClassification: DEFAULT_StrategicClassificationValues
    }

    const resolver = useYupValidationResolver(TECHNOLOGY_OBJECT_SCHEMA);
    const { register, handleSubmit, formState: {errors}, setValue, control } = useForm({
        resolver,
        defaultValues
    });

    useEffect(()=>{
        if (node) {
            Object.keys(node).forEach(k => setValue(k, node[k]))
        }
    }, [node, setValue])

    useEffect(()=>{
        LOGGER.debug("errors:", errors)
    }, [errors])

    const {user} = useSecurityContext()
    const {subscription} = useSubscriptionContext()

    let theDescriptionGenerator = false

    if (subscription === "PRO") {
        theDescriptionGenerator = ()=>descriptionGenerator(node, user)

    }

    return (
        <div>
            <Dialog open={isOpen} onClose={handleClose}>
                <DialogTitle className={cssStyles.dialogTitle}>{titleOfForm}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {descriptionOfForm}
                    </DialogContentText>
                    <form
                        data-testid={"data-object-editor-form"}
                        className={cssStyles.form}
                        onSubmit={handleSubmit(data => handleClose(data))}
                        noValidate
                    >
                        <input
                            type={"hidden"}
                            name={"id"}
                            value={node?.id}
                            {...register("id")}
                        />
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            name={"name"}
                            label="Name"
                            type="text"
                            fullWidth
                            variant="standard"
                            {...register("name")}
                            error={!!errors?.name}
                            helperText={errors?.name ? errors.name.message : null}
                        />
                        <MultilineTextField
                            id={"description"}
                            label={"Description"}
                            {...register("description")}
                            error={!!errors?.description}
                            helperText={errors?.description ? errors.description.message : null}
                            minRows={5}
                            maxRows={5} //to avoid crashes see: https://github.com/patrizz/kenchiku/issues/244
                            generator={theDescriptionGenerator}
                        />
                        <StrategicClassificationSelector
                            label={"The strategic classification of the application"}
                            name={"strategicClassification"}
                            control={control}
                            error={!!errors?.strategicClassification}
                            helperText={errors?.strategicClassification ? errors.strategicClassification.message : null}
                        />
                        <ConfidentialitySelector
                            label={"Confidentiality"}
                            name={"confidentiality"}
                            control={control}
                            error={!!errors?.confidentiality}
                            helperText={errors?.confidentiality ? errors.confidentiality.message : null}
                        />
                        <IntegritySelector
                            label={"Integrity"}
                            name={"integrity"}
                            control={control}
                            error={!!errors?.integrity}
                            helperText={errors?.integrity ? errors.integrity.message : null}
                        />
                        <AvailabilitySelector
                            label={"Availability"}
                            name={"availability"}
                            control={control}
                            error={!!errors?.availability}
                            helperText={errors?.availability ? errors.availability.message : null}
                        />
                        <RPOSelector
                            label={"Recovery Point Objective (RPO)"}
                            name={"rpo"}
                            control={control}
                            error={!!errors?.rpo}
                            helperText={errors?.rpo ? errors.rpo.message : null}
                        />
                        <RTOSelector
                            label={"Recovery Time Objective (RTO)"}
                            name={"rto"}
                            control={control}
                            error={!!errors?.rto}
                            helperText={errors?.rto ? errors.rto.message : null}
                        />
                        <SupportedRateOfChangeSelector
                            label={"Supported Rate of Change (SRoC)"}
                            name={"supportedRateOfChange"}
                            control={control}
                            error={!!errors?.supportedRateOfChange}
                            helperText={errors?.supportedRateOfChange ? errors.supportedRateOfChange.message : null}
                        />
                        <NodeSelector
                            label={"Supported Capabilities"}
                            nodeType={"capability"}
                            nodeTypeName={"Capability"}
                            nodeRootFolderId={"_capabilities"}
                            multiSelect={true}
                            name={"supportedCapabilityIds"}
                            control={control}
                            error={!!errors?.supportedCapabilityIds}
                            helperText={errors?.supportedCapabilityIds ? errors.supportedCapabilityIds.message : null}
                        />
                        <NodeSelector
                            label={PARENT_NODE_LABEL}
                            nodeType={PARENT_NODE_TYPE}
                            nodeTypeName={PARENT_NODE_TYPE_NAME}
                            nodeRootFolderId={PARENT_NODE_ROOT_ID}
                            multiSelect={false}
                            name={"parentId"}
                            control={control}
                            error={!!errors?.parentId}
                            helperText={errors?.parentId ? errors.parentId.message : null}
                        />
                        <LifeCycleStageSelector
                            label={"The life-cycle stage of the application"}
                            name={"lifeCycleStage"}
                            control={control}
                            error={!!errors?.lifeCycleStage}
                            helperText={errors?.lifeCycleStage ? errors.lifeCycleStage.message : null}
                        />
                        <TextField
                            margin="dense"
                            id="introductionCost"
                            name={"introductionCost"}
                            label="Introduction Cost"
                            type="number"
                            fullWidth
                            variant="standard"
                            {...register("introductionCost")}
                            error={!!errors?.introductionCost}
                            helperText={errors?.introductionCost ? errors.introductionCost.message : null}
                        />
                        <TextField
                            margin="dense"
                            id="yearlyCost"
                            name={"yearlyCost"}
                            label="Yearly Cost"
                            type="number"
                            fullWidth
                            variant="standard"
                            {...register("yearlyCost")}
                            error={!!errors?.yearlyCost}
                            helperText={errors?.yearlyCost ? errors.yearlyCost.message : null}
                        />
                        <DialogActions className={cssStyles.actionsDiv}>
                            <Button onClick={handleCancel}>Cancel</Button>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={cssStyles.submit}
                            >Save</Button>
                        </DialogActions>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    );
}
