import React, {createContext, useContext, useRef, useState} from 'react';
import Logger from "../../utils/Logger";
import {NodeType} from "../../model/Constants";
import ActorEditor from "../TreeNavigator2/editors/ActorEditor";
import ApplicationEditor from "../TreeNavigator2/editors/ApplicationEditor";
import CapabilityEditor from "../TreeNavigator2/editors/CapabilityEditor";
import DataExchangeEditor from "../TreeNavigator2/editors/DataExchangeEditor";
import DataFlowEditor from "../TreeNavigator2/editors/DataFlowEditor";
import DataObjectEditor from "../TreeNavigator2/editors/DataObjectEditor";
import DesignDecisionEditor from "../TreeNavigator2/editors/DesignDecisionEditor";
import FunctionalityEditor from "../TreeNavigator2/editors/FunctionalityEditor";
import MaturityModelAnalysisEditor from "../TreeNavigator2/editors/MaturityModelAnalysisEditor";
import MiddlewareEditor from "../TreeNavigator2/editors/MiddlewareEditor";
import PrincipleEditor from "../TreeNavigator2/editors/PrincipleEditor";
import ReferenceArchitectureEditor from "../TreeNavigator2/editors/ReferenceArchitectureEditor";
import ScenarioEditor from "../TreeNavigator2/editors/ScenarioEditor";
import TalEditor from "../TreeNavigator2/editors/TalEditor";
import ApplicationViewEditor from "../TreeNavigator2/editors/ApplicationViewEditor";
import MessagePopup from "../../utils/MessagePopup";
import BusinessProcessEditor from "../TreeNavigator2/editors/BusinessProcessEditor";
import ActorActivityEditor from "../TreeNavigator2/editors/ActorActivityEditor";
import TechnologyEditor from "../TreeNavigator2/editors/TechnologyEditor";

const LOGGER = new Logger("CreateOrEditDialogProvider")

export const CreateOrEditDialogContext = createContext();

export const useCreateOrEditDialog = () => {
    return useContext(CreateOrEditDialogContext);
};


function createDialog({node, isOpen, onClose, onCancel}) {
    switch (node?.type) {
        case NodeType.Actor.description:
            return <ActorEditor
                isOpen={isOpen}
                titleOfForm={"Edit actor"}
                descriptionOfForm={"Update your actor in this form."}
                node={node}
                onClose={(updatedNode)=>{if (onClose) {onClose(updatedNode)}}}
                onCancel={()=>{if (onCancel) {onCancel()}}}
            />
        case NodeType.ActorActivity.description:
            return <ActorActivityEditor
                isOpen={isOpen}
                titleOfForm={"Edit actor activity"}
                descriptionOfForm={"Update your actor activity in this form."}
                node={node}
                onClose={(updatedNode)=>{if (onClose) {onClose(updatedNode)}}}
                onCancel={()=>{if (onCancel) {onCancel()}}}
            />
        case NodeType.Application.description:
            return <ApplicationEditor
                isOpen={isOpen}
                titleOfForm={"Edit application"}
                descriptionOfForm={"Update your application in this form."}
                node={node}
                onClose={(updatedNode)=>{if (onClose) {onClose(updatedNode)}}}
                onCancel={()=>{if (onCancel) {onCancel()}}}
            />
        case NodeType.ApplicationView.description:
            //isOpen, formTitle, formDescription, nodeId, name, description, parentId, onClose, onCancel
            return <ApplicationViewEditor
                isOpen={isOpen}
                titleOfForm={"Edit view"}
                descriptionOfForm={"Update your view in this form."}
                node={node}
                onClose={(updatedNode)=>{if (onClose) {onClose(updatedNode)}}}
                onCancel={()=>{if (onCancel) {onCancel()}}}
            />
        case NodeType.BusinessProcess.description:
            return <BusinessProcessEditor
                isOpen={isOpen}
                titleOfForm={"Edit business process"}
                descriptionOfForm={"Update your business process in this form."}
                node={node}
                onClose={(updatedNode)=>{if (onClose) {onClose(updatedNode)}}}
                onCancel={()=>{if (onCancel) {onCancel()}}}
            />
        case NodeType.Capability.description:
            //isOpen, formTitle, formDescription, nodeId, name, description, parentId, onClose, onCancel
            return <CapabilityEditor
                isOpen={isOpen}
                titleOfForm={"Edit capability"}
                descriptionOfForm={"Update your capability in this form."}
                node={node}
                onClose={(updatedNode)=>{if (onClose) {onClose(updatedNode)}}}
                onCancel={()=>{if (onCancel) {onCancel()}}}
            />
        case NodeType.DataExchange.description:
            return <DataExchangeEditor
                isOpen={isOpen}
                titleOfForm={"Edit Data Exchange"}
                descriptionOfForm={"Update your data exchange in this form."}
                node={node}
                onClose={(updatedNode)=>{if (onClose) {onClose(updatedNode)}}}
                onCancel={()=>{if (onCancel) {onCancel()}}}
            />
        case NodeType.DataFlow.description:
            return <DataFlowEditor
                isOpen={isOpen}
                titleOfForm={"Edit Data Flow"}
                descriptionOfForm={"Update your data flow in this form."}
                node={node}
                onClose={(updatedNode)=>{
                    if (onClose) {onClose(updatedNode)}
                }}
                onCancel={()=>{if (onCancel) {onCancel()}}}
            />
        case NodeType.DataObject.description:
            return <DataObjectEditor
                isOpen={isOpen}
                titleOfForm={"Edit Data Object"}
                descriptionOfForm={"Update your data object in this form."}
                node={node}
                onClose={(updatedNode)=>{if (onClose) {onClose(updatedNode)}}}
                onCancel={()=>{if (onCancel) {onCancel()}}}
            />
        case NodeType.DesignDecision.description:
            return <DesignDecisionEditor
                isOpen={isOpen}
                titleOfForm={"Edit Design Decision"}
                descriptionOfForm={"Update your design decision in this form."}
                node={node}
                onClose={(updatedNode)=>{if (onClose) {onClose(updatedNode)}}}
                onCancel={()=>{if (onCancel) {onCancel()}}}
            />
        case NodeType.Functionality.description:
            return <FunctionalityEditor
                isOpen={isOpen}
                titleOfForm={"Edit Functionality"}
                descriptionOfForm={"Update your functionality in this form."}
                node={node}
                onClose={(updatedNode)=>{if (onClose) {onClose(updatedNode)}}}
                onCancel={()=>{if (onCancel) {onCancel()}}}
            />
        case NodeType.MaturityModelAnalysis.description:
            return <MaturityModelAnalysisEditor
                isOpen={isOpen}
                titleOfForm={"Edit Maturity Model Analysis"}
                descriptionOfForm={"Update your maturity model analysis in this form."}
                node={node}
                onClose={(updatedNode)=>{if (onClose) {onClose(updatedNode)}}}
                onCancel={()=>{if (onCancel) {onCancel()}}}
            />
        case NodeType.Middleware.description:
            return <MiddlewareEditor
                isOpen={isOpen}
                titleOfForm={"Edit Middleware"}
                descriptionOfForm={"Update your middleware in this form."}
                node={node}
                onClose={(updatedNode)=>{if (onClose) {onClose(updatedNode)}}}
                onCancel={()=>{if (onCancel) {onCancel()}}}
            />
        case NodeType.Principle.description:
            return <PrincipleEditor
                isOpen={isOpen}
                titleOfForm={"Edit Principle"}
                descriptionOfForm={"Update your principle in this form."}
                node={node}
                onClose={(updatedNode)=>{if (onClose) {onClose(updatedNode)}}}
                onCancel={()=>{if (onCancel) {onCancel()}}}
            />
        case NodeType.ReferenceArchitecture.description:
            return <ReferenceArchitectureEditor
                isOpen={isOpen}
                titleOfForm={"Edit Reference Architecture"}
                descriptionOfForm={"Update your reference architecture in this form."}
                node={node}
                onClose={(updatedNode)=>{if (onClose) {onClose(updatedNode)}}}
                onCancel={()=>{if (onCancel) {onCancel()}}}
            />
        case NodeType.Scenario.description:
            return <ScenarioEditor
                isOpen={isOpen}
                titleOfForm={"Edit scenario"}
                descriptionOfForm={"Update your scenario in this form."}
                node={node}
                onClose={(updatedNode)=>{if (onClose) {onClose(updatedNode)}}}
                onCancel={()=>{if (onCancel) {onCancel()}}}
            />
        case NodeType.Tal.description:
            //isOpen, formTitle, formDescription, nodeId, name, description, parentId, onClose, onCancel
            return <TalEditor
                isOpen={isOpen}
                titleOfForm={"Edit TAL"}
                descriptionOfForm={"Update your TAL in this form."}
                node={node}
                onClose={(updatedNode)=>{if (onClose) {onClose(updatedNode)}}}
                onCancel={()=>{if (onCancel) {onCancel()}}}
            />
        case NodeType.Technology.description:
            return <TechnologyEditor
                isOpen={isOpen}
                titleOfForm={"Edit TAL"}
                descriptionOfForm={"Update your TAL in this form."}
                node={node}
                onClose={(updatedNode)=>{if (onClose) {onClose(updatedNode)}}}
                onCancel={()=>{if (onCancel) {onCancel()}}}
            />
        default:
            return <MessagePopup
                isOpen={isOpen}
                title={"alert"}
                message={"Unsupported node type"}
                buttonLabel={"OK"}
                onClose={()=>{if (onClose) {onClose(node)}}}
            />
    }
}

export const CreateOrEditDialogProvider = ({ children }) => {

    const [nodeToEdit, setNodeToEdit] = useState(null);
    const onCloseHandler = useRef(null)
    const onCancelHandler = useRef(null)


    let setOnCloseAndOnCancel = (onClose, onCancel) => {
        onCloseHandler.current = (updatedNode) => {
            if (updatedNode) {
                LOGGER.debug("Updated node: ", updatedNode)
            }
            setNodeToEdit(null)
            if (onClose) {
                onClose(updatedNode)
            }
        }
        onCancelHandler.current = () => {
            if (onCancel) {
                onCancel()
            }
            setNodeToEdit(null)
        }
    }

    let editNode = (nodeWithId, onClose, onCancel) => {
        if (!nodeWithId?.id) {
            LOGGER.error("nodeWithId must have an id")
            return
        }
        setNodeToEdit(nodeWithId);
        setOnCloseAndOnCancel(onClose, onCancel);
    }

    const newNode = (newNode, onClose, onCancel) => {
        setNodeToEdit(newNode);
        setOnCloseAndOnCancel(onClose, onCancel);
    }

    return (
        <CreateOrEditDialogContext.Provider value={{
            editNode, newNode
        }}>
            {children}
            {createDialog({
                isOpen: nodeToEdit !== null,
                node: nodeToEdit,
                onClose: onCloseHandler.current,
                onCancel: onCancelHandler.current
            })}
        </CreateOrEditDialogContext.Provider>
    );
};
