import {extractTextContent} from "./HtmlUtils";
import Logger from "./Logger";
import {string, addMethod} from 'yup';

const LOGGER = new Logger("YupUtils")

export function addHtmlContentCheck() {

    if (typeof string().checkTextContentNotEmpty === "function") {
        LOGGER.debug("checkTextContentNotEmpty already exists")
        return
    }

    addMethod(string, "checkTextContentNotEmpty", function (errorMessage) {
        LOGGER.debug("checkTextContentNotEmpty - ENTER")
        return this.test(`test-html-text-content-not-empty`, errorMessage, function (value) {
            LOGGER.debug("checkTextContentNotEmpty - test - ENTER")
            const { path, createError } = this;
            let textContent = extractTextContent(value, true)
            if (!textContent || textContent.trim().length === 0) {
                LOGGER.debug("checkTextContentNotEmpty - test - ERROR, errorMessage: ", errorMessage)
                return createError({ path, message: errorMessage });
            }
            LOGGER.debug("checkTextContentNotEmpty - test - OK")
            return true
        });
    });
}
