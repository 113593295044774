import cssStyles from "./ClickableInlineObjectList.module.css"
import {useModel} from "../../model/ModelContext";
import {useSelectedNodes} from "../SelectedNodes/SelectedNodesProvider";
import {NODE_FILL_COLORS} from "../../utils/NodeTypeColors";
import Logger from "../../utils/Logger";

const LOGGER = new Logger("ClickableInlineObject")

export function ClickableInlineObject({tagKey, objectId}) {
    const {getNodeById} = useModel()
    const {setSoftSelectedNodeById} = useSelectedNodes()

    if (!tagKey) {
        LOGGER.warn("tagKey is null")
        return <></>
    }
    if (!objectId) {
        LOGGER.warn("objectId is null")
        return <></>
    }

    const onClick = (objectId) => {
        LOGGER.debug("Clicked on objectId: ", objectId)
            setSoftSelectedNodeById(objectId)
        }
    const object = getNodeById(objectId)
    const color = NODE_FILL_COLORS[object.type]
    return (
        <span key={tagKey + "-" + objectId} className={cssStyles.objectListName} style={{color:color}} onClick={() => onClick(object.id)}>{object.name}</span>
    )
}
