import * as jointjs from "@joint/plus";
import {getApplicationGhostData, getApplicationMasterData, getApplicationSecondaryData} from "../utils/DiagramUtils";
import Logger from "../../../../utils/Logger";
import {AC_RECT_DEFAULTSIZE, AC_RECT_LABEL, ARROW_TYPES, DO_RECT_LABEL} from "./ApplicationComponentPaperConstants";
import {NodeType} from "../../../../model/Constants";
import {
   findLinksWithAttribute, getCustomProp,
    hasType,
    showDialog
} from "../utils/JointjsUtils";
import {
    setApplicationNodeToElement,
    setDataExchangeNodeOnLink,
    setDataObjectNodeToElement,
    setTechnologyNodeToElement
} from "./ApplicationViewTransformers";
import {DataExchange} from "../../model/DataExchange";
import {NODE_FILL_COLORS} from "../../../../utils/NodeTypeColors";

const LOGGER = new Logger("ApplicationComponentPaperFunctions")

export function getMatchingDataExchanges(getNodesByType, sourceApplicationId, targetApplicationId, dataObjectId) {
    const dataExchanges = getNodesByType(NodeType.DataExchange.description)
    //find the dataexchange that matches the source and target application
    //then add an indication on whether the dataObject is part of the dataexchange or not
    const matchingSourceAndTarget = dataExchanges.filter((dataExchange) => {
        return dataExchange.sourceApplicationId === sourceApplicationId && dataExchange.targetApplicationId === targetApplicationId
    })
    const withMatchingInfo = matchingSourceAndTarget.map((dataExchange) => {
        return {dataExchange: dataExchange, isExactMatch: dataExchange.dataObjectIds.includes(dataObjectId)}
    })
    return withMatchingInfo
}

export function computeFontSize(rectWidth, rectHeight, text) {
    const context = document.createElement('canvas').getContext('2d');

    let fontSize = rectHeight;  // initial value
    context.font = `${fontSize}px Arial`;

    while (context.measureText(text).width > rectWidth && fontSize > 0) {
        fontSize -= 1;  // decrease the font size
        context.font = `${fontSize}px Arial`;
    }

    return fontSize;
}

export function createDataExchange(dataObject, sourceApplication, targetApplication) {
    const dataExchange = new DataExchange()
    dataExchange.name = dataObject.name + " - " + sourceApplication.name + " -> " + targetApplication.name
    dataExchange.description = "Data exchange from " + sourceApplication.name + " to " + targetApplication.name + " of data object " + dataObject.name
    dataExchange.dataObjectIds = [dataObject.id]
    dataExchange.sourceApplicationId = sourceApplication.id
    dataExchange.targetApplicationId = targetApplication.id
    dataExchange.supportingMiddlewareIds = []
    LOGGER.trace("saving dataExchange:", dataExchange)
    return dataExchange
}

export function updateDataExchangeLink(getNodeById, removeNodeById, paper, link, setStatusMessage) {

    const dataExchangeId = getCustomProp(link, "dataExchangeId")
    if (dataExchangeId) {
        const dataExchange = getNodeById(dataExchangeId)

        if (!dataExchange) {
            LOGGER.debug("dataExchange not found, removing it from the graph")
            link.remove()
            return
        }

        setDataExchangeNodeOnLink(
            getNodeById,
            removeNodeById,
            paper,
            dataExchange,
            link,
            link.getSourceCell(),
            link.getTargetCell(),
            (dataExchangeId)=>{
                setStatusMessage("Deleting data exchange: ", dataExchangeId)

                //link.id is not set yet, because the DataExchange has not yet been created!
                //but we need the dataexchange id anyway...

                //onNodeDeleteHandler(dataExchangeId)
                //removeNode(dataExchangeId)
            },
        )

    } else {
        addToolsToLink(removeNodeById, paper, link, function(zeLink) {
            LOGGER.debug("Removing link from the graph")
            if (zeLink) {
                zeLink.remove() //remove the link from the graph
            } else {
                LOGGER.debug("no link to delete")
            }
        })
    }

}


export function refreshPaper(getNodeById, searchNodes, removeNodeById, paper, setStatusMessage) {
    const graph = paper?.model
    if (!graph) {
        LOGGER.debug("no graph")
        return
    }
    /*
        get the application and dataobject rectangles and make sure they are (re-)rendered.
        And, add the tools to them as well, as those don't get saved in the JSON
     */
    graph.getCells().forEach((cell) => {
        if (hasType(cell, [NodeType.Application.description, NodeType.DataObject.description, NodeType.DataExchange.description])) {
            //then add the tools if needed
            if (hasType(cell, NodeType.Application.description)) {
                //make sure the damn thing is on the paper
                const view = paper.renderView(cell)
                //then insist on updating the view
                view.update()
                const nodeId = getCustomProp(cell, "applicationId")
                const applicationNode = getNodeById(nodeId)
                updateApplicationRectangle(paper, nodeId, applicationNode, cell, getNodeById, searchNodes)
                addToolsToComponentRectangle(cell, paper)
            } else if (hasType(cell, NodeType.DataObject.description)) {
                //const nodeId = getCustomProp(cell, "dataObjectId")
                //const dataObjectNode = getNodeById(nodeId)
                //updateDataObjectRectangle(nodeId, dataObjectNode, cell, getNodeById)
                //addToolsToComponentRectangle(cell, paper)
            }
        }
    })
    /*
      go over the links and make sure they are (re-)rendered.
      And, add the tools to them as well, as those don't get saved in the JSON
     */

    graph.getLinks().forEach((link) => {
        updateDataExchangeLink(
            getNodeById,
            removeNodeById,
            paper,
            link,
            setStatusMessage
        )

    })



}


export function createDataExchangeLink(
    getNodeById,
    searchNodes,
    removeNodeById,
    paper,
    sourceRectangleId,
    targetRectangleId,
    selectedArrowType,
    df,
    setStatusMessage
) {


    if (!sourceRectangleId || !targetRectangleId) {
        LOGGER.debug("source or target not defined, returning")
        setStatusMessage("Source or target not defined")
        return
    }

    const saId = getCustomProp(paper.model.getCell(sourceRectangleId), "applicationId")
    const taId = getCustomProp(paper.model.getCell(targetRectangleId), "applicationId")

    const sourceNode = getNodeById(saId)
    const targetNode = getNodeById(taId)

    if (!sourceNode || !targetNode) {
        LOGGER.debug("source or target not found, returning")
        setStatusMessage("Source or target not found")
        return
    }

    if (sourceNode.type !== NodeType.Application.description) {
        LOGGER.debug("source not an Application, returning")
        setStatusMessage("Source not an Application")
        return
    }

    if (targetNode.type !== NodeType.Application.description) {
        LOGGER.debug("target not an application, returning")
        setStatusMessage("Target not an application")
        return
    }

    const graph = paper.model
    const link = createLink(graph,
        sourceRectangleId,
        targetRectangleId,
        selectedArrowType,
        df?.dataObjectIds?.map(doId => (getNodeById(doId)?.name || "-")).join(", ")
    )
    setDataExchangeNodeOnLink(
        getNodeById,
        removeNodeById,
        paper,
        df,
        link,
        sourceRectangleId,
        targetRectangleId,
        (dataExchangeId) => {
            setStatusMessage("Deleting data exchange: ", dataExchangeId)
            //onNodeDeleteHandler(dataExchangeId)
        }
    )
    if (!link) {
        setStatusMessage("Can't create link")
        //shouldn't happen, but you never know...
        LOGGER.debug("undefined link, strange...")
        return
    }
    graph.addCell(link); //add it to the graph, otherwise we can't add the tools...
    addToolsToLink(removeNodeById, paper, link)
    link.source(graph.getCell(sourceRectangleId))
    link.target(graph.getCell(targetRectangleId))
    setTimeout(() => {

        refreshPaper(getNodeById, searchNodes, removeNodeById, paper.current, setStatusMessage);

    }, 5)
    return link;
}

export function revealDataExchange(
    getNodeById,
    searchNodes,
    removeNodeById,
    paper,
    setStatusMessage,
    dataExchangeId,
    sourceRectangleId,
    targetRectangleId
) {

    LOGGER.debug("entering revealDataExchange");

    const dataExchangeNode = getNodeById(dataExchangeId)
    if (!dataExchangeNode) {
        LOGGER.debug("dataExchangeNode not found, returning")
        setStatusMessage("Data exchange not found")
        LOGGER.debug("leaving revealDataExchange");
        return false
    }

    const graph = paper.model
    const links = findLinksWithAttribute(graph, "dataExchangeId", dataExchangeId)

    if (links.length > 0) {
        /*
        make sure source and target are properly set, because sometimes the link is not properly set
         */
        links.forEach(link => {
            link.source(graph.getCell(sourceRectangleId))
            link.target(graph.getCell(targetRectangleId))
            setDataExchangeNodeOnLink(
                getNodeById,
                removeNodeById,
                paper,
                dataExchangeNode,
                link,
                sourceRectangleId,
                targetRectangleId,
                (dataExchangeId) => {
                    setStatusMessage("Deleting data exchange: ", dataExchangeId)
                    //onNodeDeleteHandler(dataExchangeId)
                }
            )
        })
        LOGGER.debug("data exchange already exists, returning")
        setStatusMessage("Data exchange already on graph")
        LOGGER.debug("leaving revealDataExchange");
        return false
    }

    const dataExchangeLink = createDataExchangeLink(
        getNodeById,
        searchNodes,
        removeNodeById,
        paper,
        sourceRectangleId,
        targetRectangleId,
        "manhattan",
        dataExchangeNode,
        setStatusMessage
    )
    if (dataExchangeLink) {
        LOGGER.debug("adding dataexchange to link");
        setDataExchangeNodeOnLink(
            getNodeById,
            removeNodeById,
            paper,
            dataExchangeNode,
            dataExchangeLink,
            sourceRectangleId,
            targetRectangleId,
            (dataExchangeId) => {
                setStatusMessage("Deleting data exchange: ", dataExchangeId)
                //onNodeDeleteHandler(dataExchangeId)
            }
        )
    }
    LOGGER.debug("leaving revealDataExchange with the link");
    return dataExchangeLink
}

export function getApplicationViewAtPosition(graph, x, y) {
    return graph.findModelsFromPoint({x: x, y: y}).find((cell) => hasType(cell, NodeType.Application.description))
}
export function getDataObjectViewAtPosition(graph, x, y) {
    return graph.findModelsFromPoint({x: x, y: y}).find((cell) => hasType(cell, NodeType.DataObject.description))
}

export function createDataObjectRectangle(
    startY, width,
    dataObject, applicationRectangle, index, doHeightJump, paper, role="master") {
    const fontSize = computeFontSize(width - DO_RECT_LABEL.paddingSides * 2, DO_RECT_LABEL.height, (dataObject?.name || "ERROR"))

    let borderColor = "#222222" //master border color
    if (role === "slave") {
        borderColor = "#AAAAAA"
    } else if (role === "ghost") {
        borderColor = "#ffae00"
    }

    let dataObjectRectangle = new jointjs.shapes.standard.Rectangle({
        position: {
            x: applicationRectangle.attributes.position.x + DO_RECT_LABEL.paddingSides,
            y: applicationRectangle.attributes.position.y + AC_RECT_LABEL.height + index * doHeightJump + startY
        },
        size: {width: width - 2 * DO_RECT_LABEL.paddingSides, height: DO_RECT_LABEL.height},
        magnet: false,
        attrs: {
            fill: 'white',
            body: {
                fill: '#9fc9f5',
                stroke: borderColor,
                strokeWidth: 1,
            },
            label: {
                fontSize: Math.min(fontSize, DO_RECT_LABEL.defaultFontSize),
                text: (dataObject?.name || "-"),
                textWrap: {
                    width: AC_RECT_DEFAULTSIZE.width - DO_RECT_LABEL.paddingSides * 2,
                    height: DO_RECT_LABEL.height, // height restriction
                    ellipsis: true // ellipsis after the wrap
                }
            },
        },
    });

    paper.model.addCells([dataObjectRectangle])
    applicationRectangle.embed(dataObjectRectangle)
    setDataObjectNodeToElement(paper.model, dataObject, applicationRectangle, dataObjectRectangle, role)
}

export function createTechnologyRectangle(
    startY, width,
    technology, applicationRectangle, index, doHeightJump, paper) {
    const fontSize = computeFontSize(width - DO_RECT_LABEL.paddingSides * 2, DO_RECT_LABEL.height, (technology?.name || "ERROR"))

    let borderColor = "#222222" //master border color

    let technologyRectangle = new jointjs.shapes.standard.Rectangle({
        position: {
            x: applicationRectangle.attributes.position.x + DO_RECT_LABEL.paddingSides,
            y: applicationRectangle.attributes.position.y + AC_RECT_LABEL.height + index * doHeightJump + startY
        },
        size: {width: width - 2 * DO_RECT_LABEL.paddingSides, height: DO_RECT_LABEL.height},
        magnet: false,
        attrs: {
            fill: 'white',
            body: {
                fill: NODE_FILL_COLORS[NodeType.Technology.description],
                stroke: borderColor,
                strokeWidth: 1,
            },
            label: {
                fontSize: Math.min(fontSize, DO_RECT_LABEL.defaultFontSize),
                text: (technology?.name || "-"),
                textWrap: {
                    width: AC_RECT_DEFAULTSIZE.width - DO_RECT_LABEL.paddingSides * 2,
                    height: DO_RECT_LABEL.height, // height restriction
                    ellipsis: true // ellipsis after the wrap
                }
            },
        },
    });

    paper.model.addCells([technologyRectangle])
    applicationRectangle.embed(technologyRectangle)
    setTechnologyNodeToElement(paper.model, technology, applicationRectangle, technologyRectangle)
}


function sizeApplicationRectangleAndShowDataObjectsAndTechnology(paper,
                                                    nodeId,
                                                    applicationRectangle,
                                                    getNodeById,
                                                    searchNodes,
                                                    applicationNode,
                                                    x, y,
                                                    showMasterData) {



    if (!applicationNode) {
        LOGGER.debug("no applicationNode")
        return
    }

    if (!applicationRectangle) {
        LOGGER.debug("no applicationRectangle")
        return
    }

    //master data
    const mds = (showMasterData?getApplicationMasterData(getNodeById, applicationNode.id):[])
    //secondary data
    const sds = (showMasterData?getApplicationSecondaryData(getNodeById, searchNodes,applicationNode.id):[])
    //ghost data: data that is not in the application, but that is exchanged between this application and another
    const sdsIds = sds.map(sd=>sd.id)
    const gds = (showMasterData?getApplicationGhostData(getNodeById, searchNodes,applicationNode.id, sdsIds):[])

    const technologies = applicationNode.supportingTechnologyIds?.map(techId=>getNodeById(techId)) || []

    const numberOfMasterDataObjects = mds.length || 0
    const numberOfSecondaryDataObjects = sds.length || 0
    const numberOfGhostDataObjects = gds.length || 0
    const numberOfTechnologies = technologies.length || 0

    const doHeightJump = DO_RECT_LABEL.height + DO_RECT_LABEL.paddingHeight

    const height = Math.max(
        (numberOfMasterDataObjects + numberOfSecondaryDataObjects + numberOfGhostDataObjects + numberOfTechnologies + 1)
        * doHeightJump + 2 * DO_RECT_LABEL.paddingHeight, 50)
    const width = 100

    let label = {
        height: AC_RECT_LABEL.height,
        text: applicationNode.name,
        textWrap: {
            width: 90, // reference width minus 10
            height: AC_RECT_LABEL.height,
            ellipsis: true, // ellipsis after the wrap
        }
    }

    if (numberOfSecondaryDataObjects>0 || numberOfMasterDataObjects>0 || numberOfGhostDataObjects>0 || numberOfTechnologies>0) {
        label.y = ( AC_RECT_LABEL.height + label.height) / 2
        label.refY = -18
        //label.textVerticalAnchor= 'top'
    }

    let fillColor
    let strokeColor
    if (!nodeId || getNodeById(nodeId) === undefined) {
        fillColor = 'lightgrey'
        strokeColor = 'lightgrey'
    } else {
        fillColor = NODE_FILL_COLORS[NodeType.Application.description]
        strokeColor = NODE_FILL_COLORS[NodeType.Application.description]
    }

    const attributes = {
        fill: fillColor,
        stroke: strokeColor,
        strokeWidth: 1,
        body: {
            fill: fillColor,
            stroke: strokeColor,
            strokeWidth: 1,
        },
        label: label
    }
    if (x && y) {
        applicationRectangle.position(x, y)
    }
    applicationRectangle.attr(attributes)

    applicationRectangle.resize(width, height)
    const embeddedCells = applicationRectangle.getEmbeddedCells()
    paper.model.removeCells(embeddedCells)


    mds.forEach((md, index) => {
        createDataObjectRectangle(0, width,md, applicationRectangle, index, doHeightJump, paper, "master");
    })

    sds.forEach((sd, index) => {
        createDataObjectRectangle(numberOfMasterDataObjects*doHeightJump, width, sd, applicationRectangle, index, doHeightJump, paper, "slave");
    })

    gds.forEach((gd, index) => {
        createDataObjectRectangle((numberOfMasterDataObjects+numberOfSecondaryDataObjects)*doHeightJump, width, gd, applicationRectangle, index, doHeightJump, paper, "ghost");
    })

    technologies.forEach((technology, index) => {
        createTechnologyRectangle((numberOfMasterDataObjects+numberOfSecondaryDataObjects+numberOfGhostDataObjects)*doHeightJump, width, technology, applicationRectangle, index, doHeightJump, paper);
    })

}


export function createApplicationComponentRectangle(
    paper,
    getNodeById,
    searchNodes,
    applicationNode,
    x, y,
    showMasterData
) {

    if (!applicationNode) {
        LOGGER.debug("no applicationNode")
        return
    }


    let applicationRectangle = new jointjs.shapes.standard.Rectangle({});
    setApplicationNodeToElement(applicationNode, applicationRectangle)
    applicationRectangle.addTo(paper.model)

    sizeApplicationRectangleAndShowDataObjectsAndTechnology(paper, applicationNode.id, applicationRectangle, getNodeById, searchNodes, applicationNode, x, y, showMasterData)


    return applicationRectangle;
}

export function updateApplicationRectangle(paper, nodeId, applicationNode, applicationRectangle, getNodeById, searchNodes, showMasterData=true) {
    sizeApplicationRectangleAndShowDataObjectsAndTechnology(
        paper,
        nodeId,
        applicationRectangle,
        getNodeById,
        searchNodes,
        applicationNode,
        applicationRectangle.position().x, applicationRectangle.position().y,
        showMasterData
    )
}

export function updateDataObjectRectangle(nodeId, dataObjectNode, cell, getNodeById) {
    if (!nodeId || getNodeById(nodeId) === undefined) {
        cell.attr('body/stroke', 'lightgrey')
        cell.attr('body/fill', 'lightgrey')
    } else {
        cell.attr('body/stroke', "gray")
        cell.attr('body/fill', NODE_FILL_COLORS[NodeType.DataObject.description])
    }
    if (dataObjectNode?.name) {
        cell.attr("label/text", dataObjectNode.name)
    } else {
        cell.attr("label/text", "-")
    }
    cell.attr("label/textWrap/height", 90)
    cell.attr("label/textWrap/height", DO_RECT_LABEL.height)
    cell.attr("label/textWrap/ellipsis", true)
}

export function addToolsToLink(removeNodeById, paper, link) {
    if (!link) {
        LOGGER.debug("no link")
        return
    }

    let linkView = link.findView(paper);
    if (!linkView) {
        LOGGER.debug("no linkView")
        return
    }

    let verticesTool = new jointjs.linkTools.Vertices();
    let segmentsTool = new jointjs.linkTools.Segments();
    let removeButton = new jointjs.linkTools.Remove({
        action: function(evt) {
            evt.stopPropagation();

            showDialog({
                text: "Are you sure you want to delete this Data Exchange?",
                buttons: [
                    {
                        id: 'yes',
                        text: 'YES',
                        handler: () => {
                            const dataExchangeId = getCustomProp(link, "dataExchangeId")
                            if (dataExchangeId) {
                                LOGGER.debug("removing dataExchangeId", dataExchangeId)
                                removeNodeById(dataExchangeId)
                            } else {
                                LOGGER.debug("no dataExchangeId, strange...")
                            }
                            LOGGER.debug("removing link on paper")
                            link.remove()
                        }
                    }, {
                        id: 'no',
                        text: 'NO',
                        handler: () => {
                            LOGGER.debug("no")
                        },
                        sameAsClose: true,
                    }
                ]

            })
        }
    })
    const hideButton = new jointjs.linkTools.Button({
        markup: [{
            tagName: 'circle',
            selector: 'button',
            attributes: {
                'r': 7,
                'stroke': 'blue',
                'stroke-width': 3,
                'fill': 'blue',
                'cursor': 'pointer'
            }
        }, {
            tagName: 'text',
            textContent: '-',
            selector: 'icon',
            attributes: {
                'fill': 'white',
                'font-size': 10,
                'text-anchor': 'middle',
                'font-weight': 'bold',
                'pointer-events': 'none',
                'y': '0.3em'
            }
        }],
        distance: -77,
        action: function() {
            // just remove the link from the graph and don't delete the whole dataexchange
            link.remove()
        }
    });

    linkView.addTools(new jointjs.dia.ToolsView({
        tools: [verticesTool, segmentsTool, removeButton, hideButton]
    }));
}
export function addToolsToComponentRectangle(cell, paper) {
    let elementView = cell.findView(paper);
    if (elementView?.toolsView?.children?.length > 0) {
        LOGGER.debug("tools already added")
        return
    }

    const type = getCustomProp(cell, "type")

    let tools = []
    //kudos https://resources.jointjs.com/tutorial/element-tools
    let boundaryTool = new jointjs.elementTools.Boundary();
    if (type === NodeType.Application.description) {
        let deleteText = "Are you sure you want to remove the Application from the diagram?"
        let removeButton = new jointjs.elementTools.Remove({
            action: function(evt) {
                evt.stopPropagation();
                showDialog({
                    text: deleteText,
                    buttons: [
                        {
                            id: 'yes',
                            text: 'YES',
                            handler: () => {
                                cell.remove()
                            }
                        }, {
                            id: 'no',
                            text: 'NO',
                            handler: () => {
                                LOGGER.debug("no")
                            },
                            sameAsClose: true,
                        },
                    ]
                });
            }
        });
        tools = [boundaryTool, removeButton]
        let toolsView = new jointjs.dia.ToolsView({
            tools: tools
        });
        elementView.addTools(toolsView);
        elementView.hideTools();

        paper.on('element:mouseenter', function (elementView) {
            elementView.showTools();
        });

        paper.on('element:mouseleave', function (elementView) {
            elementView.hideTools();
        });

    } else if (type === NodeType.DataObject.description) {
        tools = []
    }

}

export function createLink(
    graph,
    sourceElementId,
    targetElementId,
    arrowType,
    labelText,
) {

    if (sourceElementId === targetElementId) {
        LOGGER.debug("source and target are the same, not creating link")
        return
    }
    if (!sourceElementId || !targetElementId) {
        LOGGER.debug("source or target is undefined, not creating link")
        return
    }
    if (graph.getCell(sourceElementId) === undefined || graph.getCell(targetElementId) === undefined) {
        LOGGER.debug("source or target is not in graph, not creating link")
        return
    }

    //const onClickHandler = onClick || function() {LOGGER.debug("onClickHandler not defined")}

    LOGGER.debug("createLink", arrowType)

    let linkOptions = {
        router: ARROW_TYPES[arrowType]?.router,
        connector: ARROW_TYPES[arrowType]?.connector,
        line: {
            stroke: 'black',
            strokeWidth: 2,
            targetMarker: {
                type: 'path',
                d: 'M 10 -5 0 0 10 5 z'
            }
        },
        vertices: ([]),

    };

    let link = new jointjs.shapes.standard.Link(linkOptions);
    link.source(graph.getCell(sourceElementId))
    link.target(graph.getCell(targetElementId))
    link.interactivity = { vertexAdd: true, vertexRemove: true, arrowheadMove: true, labelMove: true };
    /*
    link.appendLabel({
        attrs: {
            text: {
                text: labelText,
                y: -5,
                fill: 'black',
                fontSize: 12,
                fontWeight: 'bold',
                cursor: 'pointer'

            }
        }
    })
     */

    link.labels([
        {
            markup: [{
                tagName: 'rect',
                selector: 'body'
            }, {
                tagName: 'text',
                selector: 'label'
            }],
            attrs: {
                label: {
                    text: labelText,
                    y: -5,
                    fill: 'black',
                    fontSize: 12,
                    fontWeight: 'bold',
                    cursor: 'pointer'
                },
                body: {
                    cursor: 'pointer',
                    ref: 'label',
                    refX: '-10%',
                    refY: '-10%',
                    refWidth: '120%',
                    refHeight: '120%',
                    fill: 'white',
                    stroke: 'black',
                    strokeWidth: 2
                }
            }
        }
    ]);

    //link.addTo(graph);
    return link
}
